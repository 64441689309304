.packagesMainDiv {
  .packageListMainDiv {
    margin-top: 20px;

    .packageListCard {
      margin-top: 10px;
      padding: 0 !important;
      .firstGrid {
        padding-left: 25px;
        padding-top: 12px;
        h5 {
          font-size: 18px;
        }
        .genderAndAge {
          display: flex;
          gap: 20px;
          .gender {
            h6 {
              font-size: 14px;
            }
            p {
              font-size: 12px;
            }
          }
          .age {
            h6 {
              font-size: 14px;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
      .secondGrid {
        background: rgba(255, 239, 249, 1);

        .healthianPrice {
          h6 {
            color: rgba(106, 106, 106, 1);
            font-size: 12px;
          }
          p {
            font-size: 14px;
            font-weight: 600;
          }
          .saleprice {
            background: rgba(255, 255, 255, 1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 3px;
            padding: 5px;
            h6 {
              color: #d83f87;
            }
          }
        }
        .action {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
//   **************************View Model*******************************
.titleDiv {
  background-color: rgb(255, 239, 249);
  padding: 12px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  h5 {
    font-size: 18px;
  }
}
.viewBody {
  padding: 10px;
  .about {
    font-size: 12px;
    line-height: 1.2;
    color: rgba(68, 68, 68, 1);
  }
  .genderDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    h6 {
      font-size: 14px;
      color: rgba(68, 68, 68, 1);
    }
    p {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .testDetails {
    margin-top: 5px;
    h5 {
      color: rgba(0, 0, 0, 1) !important;
      font-size: 14px;
    }
    li {
      font-size: 12px;
    }
  }
  .categories {
    margin-top: 5px;
    h4 {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .Prices {
    margin-top: 5px;
    h4 {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .healthianPrice {
    h6 {
      color: rgba(0, 160, 168, 1);
      font-weight: 700;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .ourPrice {
    h6 {
      font-weight: 700;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .salePrice {
    h5 {
      font-weight: 700;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

// editmodal

.editModalForm {
  padding: 10px;
  h4 {
    font-weight: 700;
    font-size: 14px;
  }
  .Categories {
    display: flex;
    flex-wrap: wrap;
  }
  .healthianPrice {
    h6 {
      color: rgba(0, 160, 168, 1);
      font-weight: 700;
      font-size: 14px;
    }
  }
  .ourPrice {
    h6 {
      font-weight: 700;
      font-size: 14px;
    }
  }
  .salePrice {
    h5 {
      font-weight: 700;
      font-size: 14px;
    }
  }
  .error {
    font-size: 12px;
    color: red;
  }
}
