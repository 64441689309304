@import "./_variables.scss";

.contactcontent{
  background: linear-gradient(150deg, #2d0969, #100344);
}

.contactmainbody {
  padding: 6% 50px;
  height: 100%;
  .headingbox {
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    h2 {
      color: #faf0ca;
      font-size: 40px;
      margin-top: 40px;
      font-weight: bold;
    }
    p {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      width: 50%;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }
  }
  .contentbody1 {
    margin-top: 50px;
    .leftbox {
      box-sizing: border-box;
      img {
        width: 85%;
      }
    }
    .rightbox {
      padding: 2%;
      background-color: #ffffff;
      border-radius: 5px;
      form {
        margin-top: 10px;
        .formlabel {
          font-size: 16px;
          font-weight: bold;
          color: #2d256c;
        }
        .captchamainbox {
          margin-top: 10px;
        }
        .captchabox {
          background-color: #d83f87;
          width: 50%;
          text-align: center;
          padding: 1%;
          border-radius: 5px;
        }
        button {
          width: 100%;
          padding: 8px 15px;
          cursor: pointer;
          background-color: #1a0655;
          color: #f2f2f2;
          margin-top: 10px;
          border: none;
          border-radius: 5px;
          font-size: 14px;
        }
      }
    }
  }
  .contentbody2 {
    margin-top: 50px;
    padding: 0px 0px 0px 20px;
    .leftside {
      h2 {
        font-size: 40px;
        color: #faf0ca;
        font-weight: 900;
        font-family: "Inter", sans-serif;
        margin: 0px 0px 10px 0px;
      }
      p {
        font-size: 16px;
        margin: 0px;
        color: #ffffff;
        font-weight: 400;
        line-height: 22px;
      }
      .contactdetails {
        display: flex;
        gap: 5px;
        margin-top: 10px;
      }
    }
    .rightbox {
      box-sizing: border-box;
      margin: auto;
      text-align: center;
      img {
        width: 50%;
      }
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
  .contactmainbody {
    .headingbox {
      h2 {
        font-size: 25px;
      }
      p {
        width: 100%;
        font-size: 14px;
      }
    }
    .contentbody1 {
      .leftbox {
        box-sizing: border-box;
        img {
          width: 75%;
          display: flex;
          margin: auto;
        }
      }
    }
    .contentbody2 {
      .leftside {
        h2{
          font-size: 25px;
        }
        p{
          font-size: 14px;
        }
      }
      .rightbox {
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contactmainbody {
    padding: 10% 40px;
    .headingbox {
      h2 {
        font-size: 25px;
      }
      p {
        width: 100%;
        font-size: 14px;
      }
    }
    .contentbody1 {
      .leftbox {
        box-sizing: border-box;
        img {
          width: 100%;
        }
      }
    }
    .contentbody2{
      .leftside {
        h2{
          font-size: 25px;
        }
      }
      .rightbox {
        box-sizing: border-box;
        img {
          width: 100%;
        }
      }
    }
  }
}
