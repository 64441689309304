.bookYourtestMainDiv {
  .stepperCard {
    .stepperBtns {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      font-size: 12px;
      padding: 8px 10px;
      border-radius: 3px;
      flex-direction: row;
      gap: 5px;
    }
  }

  // *********************************locationTab*****************************

  .locationTabMainDiv {
    @media screen and (max-width: 600px) {
      h5 {
        font-size: 20px;
      }
    }
    .addressMainDiv {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .addressBox {
        padding: 0px !important;
        width: 250px;
        .box1 {
          background: rgba(45, 37, 108, 1);
          height: 15px;
          padding: 10px;
          color: rgba(255, 239, 249, 1);
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 14px;
            font-weight: 600;
          }
          @media screen and (max-width: 600px) {
            p {
              font-size: 12px;
            }
          }
        }
        .box2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media screen and (max-width: 600px) {
            h6 {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  // ********************************Package tab css******************************************
  .packageTabPanelmainDiv {
    .packageSection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 30px;
      height: 400px;
      overflow-y: scroll;
      .packagesMainbox {
        border: 1px solid rgba(217, 217, 217, 1);
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .testDetails {
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 0px 15px;
          position: relative;
          margin-top: 10px;
          h5 {
            font-size: 18px;
          }
          .categories-Li {
            display: flex;
            gap: 10px;
            li {
              font-size: 12px;
            }
          }
          @media screen and (max-width: 600px) {
            h5 {
              font-size: 16px;
            }
          }
        }
        .pricebox {
          background: rgba(255, 239, 249, 1);
          padding: 10px;
          width: 120px;
          position: relative;
          .mostPopular {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #2d256c;
            padding: 5px;
            border-radius: 0px 0px 3px 3px;
            p {
              display: flex;
              align-items: center;
              gap: 2px;
              text-align: center;
              color: #ffffff;
              font-size: 10px;
            }
          }
          .priceValueBox {
            margin-top: 15px;
            p {
              font-size: 12px;
              font-weight: 500;
            }
            h6 {
              font-weight: 600;
            }
          }
          @media screen and (max-width: 600px) {
            h6 {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  // ********************************************Members tab**************************************

  .memberTabMainDiv {
    @media screen and (max-width: 600px) {
      h5 {
        font-size: 20px;
      }
    }
    .membersMainDiv {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .memberBox {
        border: 1px solid rgba(217, 217, 217, 1);
        border-radius: 3px;
        padding: 10px;
        .memberInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-weight: bold;
            font-size: 14px;
          }
          .age {
            font-size: 12px;
            color: rgba(68, 68, 68, 1);
          }
        }
      }
    }
    .membersWithPackageSec {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .memberContentCard {
        padding: 0 !important;
        display: flex;
        justify-content: space-between;
        .memberDetails {
          background: rgba(245, 243, 247, 1);
          padding: 10px;
          .name {
            font-weight: 600;
            font-size: 16px;
          }
          .genderAge {
            font-size: 12px;
          }
        }
        .packageDetails {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          padding: 10px;
          .package {
            display: flex;
            align-items: center;
            background: rgba(255, 239, 249, 1);
            padding: 10px;
            gap: 5px;
            .testName {
              font-size: 12px;
              font-weight: 700;
              width: 150px;
            }
            .price {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  // *******************************************Pickup Tab*****************************************
  .pickupTabMainDiv {
    @media screen and (max-width: 600px) {
      h5 {
        font-size: 20px;
      }
    }
    .addressMainDiv {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .addressBox {
        padding: 0px !important;
        width: 250px;
        .box1 {
          background: rgba(45, 37, 108, 1);
          height: 15px;
          padding: 10px;
          color: rgba(255, 239, 249, 1);

          p {
            font-size: 14px;
            font-weight: 600;
          }
        }
        .box2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  // ******************************************Billing Tab******************************************
  .billingTabMainDiv {
    @media screen and (max-width: 600px) {
      h5 {
        font-size: 20px;
      }
    }
    .memberDetails {
      .heading {
        font-size: 14px;
        font-weight: 600;
        color: #d63f85;
      }
      .content {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .testDetails {
      margin-top: 20px;
      .TestdataBox {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .memberName {
          font-size: 16px;
          font-weight: 600;
        }
        .packagesMainDiv {
          .testName {
            font-size: 14px;
            font-weight: 600;
            color: #d63f85;
          }
          .categories {
            display: flex;
            gap: 10px;
            li {
              font-size: 14px;
            }
          }
        }
      }
    }
    .paymentDetails {
      .heading {
        font-size: 14px;
        font-weight: 600;
        color: #d63f85;
      }
      .sideheading {
        font-size: 12px;
        font-weight: 400;
      }
      .value {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
