@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
.customerclinicviewmainbox{
    background-color: #FFFFFF;
    padding: 20px;
    
    .clinicdetailsandformbox{
        display: flex;
        border: 0.3px solid #D9D9D9;
        border-radius: 5px;
        .detailsbox{
            width: 50%;
            padding: 10px;
            .clinicdetailsbox{
                display:flex;
                gap:10px;
                width: 100%;
                img{
                    height: 100px;
                }
                .locationdetails{
                    h5{
                        font-size: 20px!important;
                        color: #2D256C!important;
                        font-weight: 700;
                    }
                    h6{
                        color: #D83F87 !important;
                        font-weight: 600!important;
                        font-size: 10px;
                        padding-left: 22px;
                    }
                }
                .locationdetails div{
                    display: flex;
                    width: 100%;
                    padding-left: 0px;
                    p{
                        font-size: 14px;
                        line-height: 15px;
                        font-weight: 400;
                    }
                }
            }
            
            
            .specialitiesdescbox{
                width: 100%;
                text-align: left;
                margin: 0px;
                padding: 0px;
                margin-top: 10px;
                .specialitydesc{
                    color: #000000;
                    font-size: 16px;
                    h6{
                        font-weight: 600;
                    }
                    width: 100%;
                }
                .specilalitiesbtnbox{
                    margin-left: 0px;
                    margin-bottom:10px;
                    width: 100%;
                    display: flex;
                    padding: 0px;
                    gap: 10px;
                    button{
                        background-color: #FFEFF9;
                        font-size: 12px;
                        width: 100px;
                        padding: 10px;
                        color: #2D256C;
                        border: none;
                        border-radius: 5px;
                    }
                }
            }
        }
        .clinicformbox{
            width: 50%;
            background-color: #F5F3F7;
            form, .forms{
                padding:20px 0px 20px 30px;
                width: 98%;
                h5{
                    color: #D83F87;
                    font-size: 20px;
                    font-weight: 600;
                }
                
                .formlabel{
                    color: #2D256C;
                    margin-top: 5px;
                    font-weight: 600;
                }
                .inputbox{
                    background-color: #FFFFFF;
                    border-radius: 5px;
                }
                button{
                    background-color: #D83F87;
                    padding: 15px;
                    border: none;
                    color: white;
                    margin-top: 10px;
                    font-weight: 600;
                    width: 90%;
                    border-radius: 5px;
                }
                .error{
                    color: red;
                }
            }
        }
    }
}
.mobileviewdetailsbox{
    display: none!important;
    .clinicformbox{
        display: none!important;
    }
}

// -------------------------media screen for mobile view---------------------
@media screen and (max-width: 600px) {
    .clinicdetailsandformbox{
        display: none!important;
        .clinicformbox{
            display: none!important;
        }
    }
        .mobileviewdetailsbox{
            display: block!important;
            .clinicimagesbox{
                display: flex;
                gap: 5px;
                .clinicmainimage{
                    object-fit: cover;
                    img{
                        height: 250px;
                        width: 250px;
                    }
                }
                .subimages{
                    display: flex;
                    flex-direction: column;
                    gap: 5px!important;
                    .imagebox{
                        width: 80px;
                        height: 80px;
                        box-sizing: border-box;
                        img{
                            width: 80px;
                            height: 80px;
                        }
                    }
                }
            }
            .clinicdetails{
                background-color: #FFF6FF;
                padding: 8px;
                margin-top: 10px;
                .clinicnameAndAddress{
                    .details{
                        display: flex;
                        gap: 5px;
                        div{
                            h5{
                                font-size: 12px;
                                color: #2D256C!important;
                            }
                        }
                        .address{
                            display: flex;
                            svg{
                                font-size: 10px;
                            }
                            p{
                                font-size: 10px;
                                color: #444444!important;
                            }
                        }
                        h6{
                            font-size: 10px;
                            color: #D83F87;
                            padding-left: 10px;
                        }
                        .bookanappointmentbtn{
                            button{
                                padding: 10px 15px;
                                font-size: 10px;
                                border: none;
                                border-radius: 5px;
                                white-space: nowrap;
                                background-color: #D83F87;
                                color: #FFFFFF;
                            }
                        }
                    }
                    .specilalitiesbtnbox{
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                        button{
                            border: none;
                            border-radius: 5px;
                            font-size: 10px;
                            padding: 5px;
                            background-color: #FFFFFF;
                            color: #2D256C;
                        }
                    }
                }
            }
        }
        .doctorscarouselbox{
            display: none;
        }
        .doctordetailsbox{
            h5{
                font-size: 14px;
                margin-top: 10px;
            }
            .doctordetailscard{
                margin-top: 10px;
            }
        }
        .css-v8ehrw{
            width: 300px!important;
                .otpformbox{
                    h5{
                        font-size: 14px;
                        text-align: center;
                    }
                    .formlabel{
                        color: #2D256C!important;
                        font-size: 12px;
                        text-align: center;
                    }
                    .submitbtn{
                        border: none;
                        border-radius: 5px;
                        background-color: #D83F87;
                        padding: 10px;
                        color: #FFFFFF;
                        margin-top: 10px;
                    }
                    .cancelbtn{
                        border: none;
                        border-radius: 5px;
                        background-color: #696969;
                        padding: 10px;
                        color: #FFFFFF;
                        margin-top: 10px;
                    }
                }
                
                .bookappointmentform{
                    form{
                        padding-left: 20px;
                    }
                    h2{
                        text-align: center;
                        font-size: 14px;
                        margin-bottom: 10px;
                        color: #D83F87!important;
                    }
                    .formlabel{
                        font-size: 12px!important;
                        color: #2D256C;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                    #inputbox{
                        height: 15px!important;
                        font-size: 12px;
                    }
                    .inputbox{
                        height: 32px!important;
                        font-size: 12px;
                    }
                    .error{
                        font-size: 8px;
                        color: red;
                    }
                    button{
                        background-color: #D83F87;
                        padding: 10px;
                        border: none;
                        font-size: 10px;
                        color: white;
                        margin-top: 10px;
                        font-weight: 600;
                        width: 90%;
                        border-radius: 5px;
                    }
                    .resetbtn{
                        background-color: #696969;
                        padding: 10px;
                        border: none;
                        font-size: 10px;
                        color: white;
                        margin-top: 10px;
                        font-weight: 600;
                        width: 90%;
                        border-radius: 5px;
                    }
                    
                }
        }
}

@media screen and (min-width:350px) and (max-width:420px){
    .clinicdetailsandformbox{
        display: none!important;
        .clinicformbox{
            display: none!important;
        }
    }
    .mobileviewdetailsbox{
        display: block!important;
        .clinicimagesbox{
            display: flex;
            gap: 5px;
            .clinicmainimage{
                object-fit: cover;
                img{
                    height: 200px;
                    width: 200px;
                }
            }
            .subimages{
                display: flex;
                flex-direction: column;
                gap: 5px!important;
                .imagebox{
                    width: 60px;
                    height: 60px;
                    box-sizing: border-box;
                    img{
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
    }
    .clinicdetails{
        background-color: #FFF6FF;
        padding: 8px;
        margin-top: 10px;
        .clinicnameAndAddress{
            .details{
                display: flex;
                gap: 5px;
                div{
                    h5{
                        font-size: 12px!important;
                        color: #2D256C!important;
                    }
                }
                .address{
                    display: flex;
                    svg{
                        font-size: 10px;
                    }
                    p{
                        font-size: 8px!important;
                        color: #444444!important;
                    }
                }
                h6{
                    font-size: 10px!important;
                    color: #D83F87;
                    padding-left: 10px;
                }
                .bookanappointmentbtn{
                    button{
                        padding: 10px 12px!important;
                        font-size: 10px!important;
                        border: none;
                        border-radius: 5px;
                        white-space: nowrap;
                        background-color: #D83F87;
                        color: #FFFFFF;
                    }
                }
            }
            .specilalitiesbtnbox{
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                button{
                    border: none;
                    border-radius: 5px;
                    font-size: 8px!important;
                    padding: 5px;
                    background-color: #FFFFFF;
                    color: #2D256C;
                }
            }
        }
    }
}
// -------------------------------------carousel----------------------------

.carouselcontainer{
    .imagebox{
        box-sizing: border-box;
        height: 150px;
        width: 150px;
        img{
            object-fit: cover;
            width: 150px;
            height: 150px;
        }
    }
}

// --------------------------doctor carousel--------------------------------

.react-multi-carousel-list{
    padding: 0px;
}
.item{
    padding:20px!important;
}
.doctorscarouselbox{
    h5{
        color: #000000!important;
    }
}
.doctorcard{
    display: flex;
    gap: 20px;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: #F5F3F7;
    img{
        height: 162px;
        width: 174px;
        padding: 0px !important;
    }
    .doctordetails{
        .box1{
            h4{
                font-size: 20px;
                font-weight: 600;
                padding: 0px!important;
            }
            p{
                font-size: 10px;

            }
        }
        .year{
            color: #959595;
            font-size: 12px;
            font-weight: 600;
        }
        .box2{
            h6{
                color: #D83F87;
                font-size: 14px;
                font-weight: 600;
            }
            .specialization{
                color: #444444;
            }
        }
        
    }
}