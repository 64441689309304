// #headingbox{
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0px 0px 10px 0px;
// }
// #headingbox h6{
//     font-weight: 800;
//     font-size: 22px;
//     color: #D83F87 ;
// }
// #headingbox button{
//     font-size: 10px;
//     font-family: Inter;
//     padding: 8px 15px;
//     font-weight: 600;
//     border: none;
//     background-color: #D83F87;
//     color: white;
//     border-radius: 3px;
//     display: flex;
//     align-items: center;
//     gap: 6px;
//     cursor: pointer;
//     svg{
//         color: #2D256C;
//     }
// }
// .MuiTabPanel-root{
//     padding: 0px!important;
// }
//  -------------------tab bottom pink color eliminated---------------------
// .PrivateTabIndicator-colorSecondary-11 {
//     background-color: none!important;
//     height: 0px!important;
// }
// .PrivateTabIndicator-root-1{
//     background-color: none!important;
//     height: 0px!important;
// }

// -------------------------------media css-------------------------
@media screen and (max-width: 600px) {
  #headingbox {
    padding: 0px 0px 10px 0px !important;
    h6 {
      font-size: 14px !important;
    }
  }
  .dateandrefreshbox {
    gap: 10px !important;
    #refreshbtn {
      font-size: 10px;
      padding: 5px;
      #refreshicon {
        font-size: 14px;
      }
    }
    #selectdate {
      font-size: 10px;
      padding: 5px;
      #calendaricon {
        font-size: 14px;
      }
    }
  }
}

// ------------------------------Password change screeen styles-------------------------------

.formcontrol {
  width: 55% !important;
  margin-bottom: 10px !important;
}

@media screen and (max-width: 600px) {
  .formcontrol {
    width: 95% !important;
  }
  .formlabel {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

// --------------------------------------clinic footer---------------------------------

.clinicfooter {
  margin-top: 10px;
}
.clinicfooter p {
  font-size: 12px;
}

.clinicfooter p a {
  color: #d83f87 !important;
}
