@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
.searchbarbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  form {
    display: flex;
    gap: 10px;
    input {
      background-color: #ffffff;
    }
    svg {
      color: #d83f87;
    }
    button {
      padding: 0px 25px;
      background-color: #2d256c;
      border: none;
      border-radius: 5px;
      height: 38px;
      color: #ffffff;
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .locationbox {
    div {
      display: flex;
      color: #d83f87;
      justify-content: right;
    }
    .yourlocation {
      font-size: 20px;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      // padding-right: 50px;
    }
  }
}
.mobilesearchbar {
  display: none;
}
.search {
  width: fit-content;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  border: 0.5px solid #c0c0c0;
  align-items: center;
  height: 38px;
  input {
    border: none !important;
    height: 33px;
    font-size: 16px;
    width: 220px;
  }
  input:focus {
    outline: none !important;
    border: none !important;
  }
  input::placeholder {
    font-size: 12px;
  }
}

// -----------------------------------Mobile Search Box---------------------------------
@media screen and (max-width: 600px) {
  .searchbarbox {
    display: none;
  }
  .search {
    width: 80%;
    background-color: #fff6ff;
  }
  .mobilesearchbar {
    display: block;
    margin-bottom: 20px;
    form {
      display: flex;
      gap: 10px;
      button {
        background-color: #fff6ff;
        border: none;
        svg {
          color: #d83f87;
        }
      }

      input {
        background-color: #fff6ff;
      }
      .filtericon {
        background-color: #d83f87;
        color: #ffffff;
        border-radius: 5px;
        width: 40px;
        height: 38px;
      }
    }
    .locationbox {
      div {
        display: flex;
        color: #d83f87;
        justify-content: left;
        align-items: center;
        svg {
          font-size: 14px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
}

// --------------------------------serviceCategorybox-----------------------------------

.serviceCategorybox {
  h6 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 17px;
  }
  .services {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    div {
      p {
        font-weight: 600;
        padding-top: 10px;
        text-align: center;
      }
    }
    .squarebox {
      background-color: #ffffff;
      height: 140px;
      border: 0.3px solid #e4e4e4;
      border-radius: 5px;
      width: 140px;
      display: flex;
      justify-content: center;
      img {
        height: 70px;
        width: 70px;
        padding-top: 30px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .serviceCategorybox {
    h6 {
      font-weight: 600;
      margin-bottom: 20px;
      font-size: 14px;
    }
    .services {
      div {
        p {
          font-weight: 600;
          padding-top: 10px;
          text-align: center;
          font-size: 10px;
        }
      }
      .squarebox {
        background-color: #ffffff;
        height: 80px;
        border: 0.3px solid #e4e4e4;
        border-radius: 5px;
        width: 80px;
        display: flex;
        justify-content: center;
        img {
          height: 50px;
          width: 50px;
          padding-top: 10px;
        }
      }
    }
  }
}
// -----------------------------------modal---------------------------------------

.modalbuttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  button {
    padding: 10px;
    width: 200px;
    border-radius: 5px;
    background-color: #2d256c;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
}
.manualpincodeform {
  form {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    input {
      width: 200px;
    }
    .input-error {
      border-color: red;
    }
    button {
      width: 30%;
      padding: 10px;
      background-color: #2d256c;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

// --------------------------------Mobile Screen Modal------------------------------------
@media screen and (max-width: 600px) {
  .css-11r052a {
    width: 200px !important;

    h2 {
      font-size: 14px;
      text-align: center !important;
    }
    p {
      font-size: 10px;
      text-align: center;
      color: #323235;
      font-weight: bold;
    }
  }
  .modalbuttons {
    display: block;
    gap: 20px;
    margin-top: 20px;
    button {
      padding: 10px;
      width: 200px;
      border-radius: 5px;
      background-color: #2d256c;
      color: #ffffff;
      border: none;
      margin-top: 10px;
      cursor: pointer;
      font-size: 12px;
    }
  }
  .manualpincodeform {
    form {
      display: block;
      gap: 10px;
      margin-top: 20px;
      input {
        padding-left: 0px;
        width: 185px;
      }
      input::placeholder {
        padding-left: 10px;
      }
      .input-error {
        border-color: red;
      }
      button {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        background-color: #2d256c;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

// ------------------------------All Clincs list and card css------------------------

.cliniclistmainbox {
  margin-top: 30px;
  .clinicheading {
    display: flex;
    justify-content: space-between;

    h5 {
      color: #2d256c !important;
      font-size: 17px;
    }
    p {
      cursor: pointer;
      font-size: 17px;
    }
  }

  .cliniccontentbox {
    margin-top: 10px;
    // background-color: #FFFFFF;
    overflow-y: scroll;
    height: 75vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .carddata {
      display: flex;
      gap: 20px;
      background-color: #ffffff;
      // border: 1px solid black;
      border-radius: 5px;
      align-items: center;
      .clinicimage{
        img{
          width: 350px;
          height: 200px;
        }
      }
      h5 {
        color: #444444 !important;
        font-size: 17px;
      }
      h6 {
        color: #d83f87 !important;
        font-size: 14px;
      }
      .address {
        color: #444444 !important;
        font-size: 14px;
      }
      .kmsbox {
        display: flex;
        background-color: #ffeff9;
        padding: 5px;
        gap: 5px;
        width: "80px";
        align-items: center;
        img {
          height: 15px;
        }
        p {
          font-size: 12px;
        }
      }
      #kmsbox {
        display: none;
      }
      .specialitybox {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        p {
          font-size: 12px;
          background-color: #ffeff9;
          padding: 5px 10px;
        }
      }
      button {
        background-color: #2d256c;
        border: none;
        padding: 8px 15px;
        color: white;
        border-radius: 3px;
        font-size: 12px;
      }
    }
  }
  .clinicmobilecontentbox {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .cliniclistmainbox{
    .cliniccontentbox{
      .carddata{
        .specialitybox{
          flex-wrap: wrap;
        }
      }
    }
  }
}

// ------------------------CSE NEW APPointments styles----------------------

.Clinicnametextinputbox{
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 250;
}
.clinicsuggestionsbox{
    position: absolute;
    background-color: white;
    z-index: 1;
    width: 250;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2),
}

.cseclinicsbox {
  .carddata {
    display: flex;
    gap: 20px;
    background-color: #ffffff;
    // border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
    h5 {
      color: #444444 !important;
      font-size: 17px;
    }
    h6 {
      color: #d83f87 !important;
      font-size: 14px;
    }
    .address {
      color: #444444 !important;
      font-size: 14px;
    }
    .kmsbox {
      display: flex;
      background-color: #ffeff9;
      padding: 5px;
      gap: 5px;
      width: "80px";
      align-items: center;
      img {
        height: 15px;
      }
      p {
        font-size: 12px;
      }
    }
    #kmsbox {
      display: none;
    }
    .specialitybox {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      p {
        font-size: 12px;
        background-color: #ffeff9;
        padding: 5px 10px;
      }
    }
    button {
      background-color: #2d256c;
      border: none;
      padding: 8px 15px;
      color: white;
      border-radius: 3px;
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .cliniclistmainbox{
    .cseclinicsbox{
      .carddata{
        .specialitybox{
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {

    .clinicsuggestionsbox{
        width: 200px;
    }
  .cliniclistmainbox {
    margin-top: 30px;
    .clinicheading {
      display: flex;
      justify-content: space-between;
      h5 {
        font-size: 14px;
        color: #2d256c !important;
      }
      p {
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .cliniccontentbox {
    display: none !important;
  }
  .clinicmobilecontentbox {
    display: block !important;
    // background-color: #FFFFFF;
    overflow-y: scroll;
    height: 75vh;
    .carddata {
      display: flex;
      gap: 20px;
      background-color: #ffffff;
      // border: 1px solid black;
      border-radius: 5px;
      align-items: center;
      margin-top: 10px;
      div {
        img {
          height: 100px !important;
          width: 100px !important;
        }
      }
      h5 {
        color: #444444 !important;
        font-size: 14px;
      }
      h6 {
        color: #d83f87 !important;
        font-size: 12px !important;
      }
      .address {
        color: #444444 !important;
        font-size: 10px !important;
      }
      .kmsbox {
        display: flex; //should check this kms imagebox
        background-color: #ffeff9;
        padding: 5px;
        gap: 5px;
        width: 50px;
        align-items: center;
        img {
          height: 12px !important;
        }
        p {
          font-size: 10px !important;
        }
      }
      .specialitybox {
        display: none !important;
      }
      button {
        background-color: #2d256c;
        display: none;
        border: none;
        padding: 5px;
        color: white;
        margin-top: 5px;
        border-radius: 5px;
        font-size: 10px;
      }
    }
  }

  // ---------------location modal box styles----------------
  .css-1wnsr1i {
    width: 200px !important;
  }



// ----------------------  Cse new appointments media styles-------
.cseclinicsbox {
    .carddata {
        display: flex;
        gap: 10px;
        background-color: #ffffff;
        // border: 1px solid black;
        border-radius: 5px;
        align-items: center;
        margin-top: 10px;
        div {
          img {
            height: 120px !important;
            width: 120px !important;
          }
        }
        h5 {
          color: #444444 !important;
          font-size: 14px;
        }
        h6 {
          color: #d83f87 !important;
          font-size: 12px !important;
        }
        .address {
          color: #444444 !important;
          font-size: 10px !important;
        }
        .kmsbox {
          display: flex; //should check this kms imagebox
          background-color: #ffeff9;
          padding: 5px;
          gap: 5px;
          width: 50px;
          align-items: center;
          img {
            height: 12px !important;
          }
          p {
            font-size: 10px !important;
          }
        }
        .specialitybox {
          display: none !important;
        }
        button {
          background-color: #2d256c;
          border: none;
          padding: 5px;
          color: white;
          margin-top: 5px;
          border-radius: 5px;
          font-size: 10px;
        }
      }
}
}
