@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
.container {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  /* background-color: #D83F87; */
}
.logo {
  width: 50%;
}
.logo img {
  width: 50%;
}
.smallscreenlogo {
  display: none;
}
.navbar {
  height: 70px;
  position: fixed;
  z-index: 100;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 34px;
  padding: 0px 45px;
  width: 100%;
  background-color: #d83f87;
  box-sizing: border-box;
}

.menu-icon {
  display: none;
}
.nav-elements {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-elements ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 17px;
  line-height: 20.7px;
  color: #1a0655;
  font-weight: 400;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #1a0655;
  font-weight: 600;
  position: relative;
}

.nav-elements ul a.active::after {
  /* content: ''; */
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.nav-elements .loginbtn {
  color: #fff;
  background-color: #1a0655;
  border: none;
  height: 40px;
  width: 100px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 6px;
}
.nav-elements .loginbtn:hover {
  background-color: #fff;
  color: #1a0655;
  cursor: pointer;
}
.nav-elements ul li a {
  color: #fff;
  text-decoration: none;
}

/*  navbar media query */
@media (max-width: 600px) {
  .nav-elements ul {
    padding: 0px;
  }
  .nav-elements ul li {
    width: fit-content;
    margin: 10px auto !important;
    text-align: center;
  }
  .nav-elements ul li a {
    margin: auto;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
    color: #d83f87;
  }
  .logo {
    display: none;
  }
  .smallscreenlogo {
    display: block;
    width: 28%;
  }
  .navbar {
    height: 61px;
    background-color: #fff;
    padding: 0px 25px;
  }

  .nav-elements ul li:last-child {
    margin-right: 60px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    width: auto;
    /* flex-direction: column; */
    display: block;
    top: 60px;
    background-color: #d83f87;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  .nav-elements > a {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
@media screen and (min-width: 600px) and (max-width: 910px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .nav-elements ul a {
    font-size: 14px;
  }
  .nav-elements .loginbtn {
    /* height: 48px; */
    width: 80px;
  }
  .logo {
    width: 80%;
  }
  .logo img {
    width: 80%;
  }
}
@media screen and (max-width: 910px) {
  .logo {
    width: 250px;
  }
}
