// .headingbox{
//     display: flex;
//     justify-content: space-between;
//     padding: 0px 25px 25px 25px;
// }
// .headingbox h6{
//     font-weight: 800;
//     font-size: 22px;
//     color: #D83F87 ;
// }
// .headingbox button{
//     font-size: 12px;
//     font-family: Inter;
//     padding: 8px 15px;
//     font-weight: 600;
//     border: none;
//     background-color: #D83F87;
//     color: white;
//     border-radius: 5px;
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     cursor: pointer;
//     svg{
//         color: #2D256C;
//     }
// }

//  -------------------tab bottom pink color eliminated---------------------
.PrivateTabIndicator-root-1{
    background-color: none!important;
    height: 0px!important;
}