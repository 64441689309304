.healthianOrder,
.RescheduleDateAndTime {
  .mainCard {
    .card1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      .nameAndEmailBox {
        .heading {
          font-size: 12px;
          font-weight: 600;
          color: #d83f87;
        }
        .content {
          font-size: 14px;
          color: #444444;
          font-weight: 600;
        }
      }
      .numberAddressBox {
        .heading {
          font-size: 12px;
          font-weight: 600;
          color: #d83f87;
        }
        .content {
          font-size: 14px;
          color: #444444;
          font-weight: 600;
        }
      }
      .landmarkSublocality {
        .heading {
          font-size: 12px;
          font-weight: 600;
          color: #d83f87;
        }
        .content {
          font-size: 14px;
          color: #444444;
          font-weight: 600;
        }
      }
    }
    .card2 {
      margin-top: 10px;
      .customerDetails {
        .title {
          font-size: 18px;
          color: #d83f87;
          font-weight: 600;
        }
        .body {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 10px;
          .heading {
            font-size: 12px;
            font-weight: 600;
            color: #d83f87;
          }
          .content {
            font-size: 14px;
            color: #444444;
            font-weight: 600;
          }
        }
      }
      .packageDetails {
        .title {
          font-size: 18px;
          color: #d83f87;
          font-weight: 600;
          margin-top: 10px;
        }
      }
    }
    .card3 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
      .heading {
        font-size: 12px;
        font-weight: 600;
        color: #d83f87;
      }
      .content {
        font-size: 14px;
        color: #444444;
        font-weight: 600;
      }
    }
  }
}
