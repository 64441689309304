

.mobilescreencard{
    display: none;
}


// -----------------------media screen for Mycoupon----------------------

@media screen and (max-width: 600px) {
    .desktopcard{
        display: none;
    }
    .mobilescreencard{
        display: block;
        .couponcodehead{
            background-color: #2D256C;
            padding: 5px;
            color: #FFFFFF;
            display: flex;
            justify-content: space-between;
        }
        .title{
            color: #D83F87;
            font-weight: 600;
        }
        .value{
            font-weight: 600;
            font-size: 12px;
            color: #444444;
        }
    }
}


// -----------------------------------Individual coupon detals----------------------------
.couponcodeheading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2D256C;
    border-radius: 8px 8px 0px 0px;
    color: #FFFFFF;
    padding: 1.5%;
    font-weight: bold;
    p{
        font-size: 14px;
    }
}
.plandesktopbody{
    padding: 1.5%;
}
.visibilityicon{
    display: none!important;
}
.planmobilebody{
    display: none;
}

// ----------------------------media screen-----------------------------
@media screen and (max-width: 600px) {
    .visibilityicon{
        display: block!important;
        font-size: 15px;
    }
    .couponcodeheading{
        p{
            font-size: 12px!important;
        }
    }
    .plandescription{
        display: none;
    }
    .plandesktopbody{
        display: none;
    }
    .planmobilebody{
        display: block!important;
        padding: 1.5%;
    }
    .title{
        font-size: 10px!important;
    }
    .value{
        font-size: 12px!important;
    }
    .specialitycovered{
        font-size: 10px !important;
    }
    .benifits{
        font-size: 12px!important;
    }
    
}