.upcomingTableMainDiv{
    border: 2px solid gray;
    border-radius: 6px;
    padding: 10px;
    .upcomingInsideMain{
        display: flex;
    justify-content: space-between;
    
    }
    button{
        font-size: 12px;
        font-family: Inter;
        padding: 8px 15px;
        font-weight: 600;
        border: none;
        background-color: #2D256C;
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }
}

// --------------------------------------customer past table scss-----------------------

.apoointinfo{
  font-size: 17px!important;
}

.customerpastappointmentmaincard {
      box-shadow: none!important;
      border: 0.5px solid #D9D9D9;
    .appointmentcard {
      padding: 0px !important;
      box-shadow: none!important;
      border: 0.5px solid #D9D9D9;
      .headbox {
        background-color: #f0f0f0;
        padding: 2%;
        h5 {
          font-size: 10px;
          font-weight: bold;
        }
        h4 {
          color: #2d2828;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .datetime {
        padding: 2%;
        gap: 50px;
        .date {
          h5 {
            font-size: 10px;
            font-weight: bold;
          }
          h4 {
            color: #2d2828;
            font-size: 12px;
            font-weight: bold;
          }
        }
        .time {
          h5 {
            font-size: 10px;
            font-weight: bold;
          }
          h4 {
            color: #2d2828;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
      .specialitybox {
        width: 25%;
        border-radius: 5px!important;
        background-color: #FFEFF9;
        margin-left: 2%;
      }
      .remarksbox {
        width: 50%;
        padding-left: 2%;
        margin: 10px 0px;
        h5 {
          font-size: 10px;
          font-weight: bold;
        }
        p {
          font-size: 12px;
          color: #444444;
        }
      }
    }

    .complaintstreatmentscard {
      margin-top: 10px;
      box-shadow: none!important;
      border: 0.5px solid #D9D9D9;
      .complaints1 {
        font-size: 12px;
      }
      .complaints2 {
        font-size: 12px;
      }
      .procedures1 {
        font-size: 12px;
      }
      .procedures2 {
        font-size: 12px;
      }
    }
    .paymentcard {
      margin-top: 10px;
      padding: 0px !important;
      box-shadow: none!important;
      border: 0.5px solid #D9D9D9;
      .paymentdetailsdesc {
        background-color: #f0f0f0;
        padding: 2%;
        h5 {
          font-size: 14px;
        }
      }
      .paymentdetailsbox {
        padding: 2%;
        .totalchargesgrid {
          padding: 1% 0% !important;
          color: #d83f87;
          h5 {
            font-size: 14px;
          }
          p {
            font-size: 14px;
          }
        }
        #sideheading{
          font-size: 14px!important;
        }
        #amount{
          font-size: 14px!important;
        }
        #treatments{
          font-size: 14px!important;
          color: #4f4f4f;
        }
        #value{
          font-size: 14px!important;
          color: #898989;
        }
        .amountpaidgrid {
          padding: 1% 0% !important;
          color: #d83f87;
          h5 {
            font-size: 14px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  
    // --------------------rightcard-------------------
    .rightcard {
      padding: 0px !important;
      box-shadow: none!important;
      border: 0.5px solid #D9D9D9;
      .rightheadbox {
        background-color: #f0f0f0;
        padding: 3.5% !important;
        .subbox1 {
          h5 {
            font-size: 12px;
            font-weight: bold;
          }
          h4 {
            display: flex;
            color: #444444;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
      .rightbodybox {
        padding: 4% !important;
        h4 {
          font-size: 12px;
          font-weight: bold;
          color: #444444;
        }
        .appointmentscard {
          padding: 0px !important;
          margin-top: 12px;
          box-shadow: none!important;
          cursor: pointer;
          border: 0.5px solid #D9D9D9;
          .cardhead {
            display: flex;
            justify-content: space-between;
            background-color: #f0f0f0;
            padding: 2% !important;
            h5 {
              font-size: 12px;
              font-weight: bold;
            }
            svg {
              font-size: 14px;
              color: #d83f87;
            }
          }
          .cardbodybox {
            padding: 2% !important;
            .datebox {
              display: flex;
              gap: 10px;
              svg {
                font-size: 12px;
              }
              h4 {
                font-size: 12px;
                font-weight: bold;
              }
            }
            .specialitybox {
              width: 30%;
              margin-top: 10px;
            }
            .analysisbox {
              margin-top: 10px;
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .customerpastappointmentmaincard {
      .paymentcard {
        .paymentdetailsbox {
          padding: 2%;
          .sideheading{
            font-size: 12px;
          }
          .amount{
            font-size: 12px;
          }
          .totalchargesgrid {
            padding: 1% 0% !important;
            color: #d83f87;
            h5 {
              font-size: 12px;
            }
            p {
              font-size: 12px;
            }
          }
          .amountpaidgrid {
            padding: 1% 0% !important;
            color: #d83f87;
            h5 {
              font-size: 12px;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  