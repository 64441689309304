.plannameheading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2D256C;
    border-radius: 8px 8px 0px 0px;
    color: #FFFFFF;
    padding: 1.5%;
    font-weight: bold;
    p{
        font-size: 14px;
    }
}
.plandesktopbody{
    padding: 1.5%;
}
.visibilityicon{
    display: none!important;
}
.planmobilebody{
    display: none;
}

// ----------------------------media screen-----------------------------
@media screen and (max-width: 600px) {
    .visibilityicon{
        display: block!important;
        font-size: 15px;
    }
    .plannameheading{
        p{
            font-size: 12px!important;
        }
    }
    .plandescription{
        display: none;
    }
    .plandesktopbody{
        display: none;
    }
    .planmobilebody{
        display: block!important;
        padding: 1.5%;
    }
    .title{
        font-size: 10px!important;
    }
    .value{
        font-size: 12px!important;
    }
    .specialitycovered{
        font-size: 10px !important;
    }
    .benifits{
        font-size: 12px!important;
    }
    .discount{
        font-size: 10px!important;
    }
    .discountvalue{
        font-size: 12px!important;
    }
    .maxdis{
        font-size: 10px!important;
    }
    .maxdisvalue{
        font-size: 12px!important;
    }
}