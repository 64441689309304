@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

$rightsidebackground:#D83F87;

.mainbox{
    .cancelIcon{
        position: absolute;
        right: 0;
        cursor: pointer;
    }
}
.Modalcontentbox{
    display: flex;
    border-radius: 5px;
    .formbox{
        width: 50%;
        padding: 20px 10px;
        box-sizing: border-box;
    }
    .formbox form{
        margin: auto;
        padding: 10px 0px 10px 20px;
    }
    .formbox form .formcontrolbox{
        margin-bottom: 5px;
        // margin-top: 5px;
    }
    .formbox form .formcontrolbox .formlabel{
        color: black;
        font-size: 14px;
        font-family: "Inter",sans-serif;
        font-weight: 520;
        margin-bottom: 5px;
    }
    .formbox img{
        width: 50%;
        padding-left: 0px;
    }
    .formbox .captchabox{
        display: flex;
        background-image: url("../assets/captcha_background.jpg");
        height: 40px;
        width: 100px;
        border-radius: 5px;
    }
    
    .formbox .captchabox p{
        font-family: "Inter",sans-serif;
        font-size: 18px;
        color: #1A0655;
        margin: auto;
        font-weight: 550;
    }
    .formbox form h3{
        margin: 0px;
    }
    
    .formbox form button{
        width: 90%;
        padding: 10px;
        cursor: pointer;
        background-color: #1A0655;
        color: #f2f2f2;
        border: none;
        border-radius: 5px;
    }
    .formrightside{
        width: 50%;
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        background-color:$rightsidebackground;
        border-radius: 0px 5px 5px 0px;
    }
    .formrightside h1{
        margin: auto;
        padding: 0px;
        width: 50%;
        font-size: 35px;
        font-weight: 800;
        color: #f2f2f2;
    }
}

@media screen and (min-width:600px) and (max-width:768px){
    .Modalcontentbox{
        .formbox{
            padding: 10px;
        }
        .formbox form{
            margin: auto;
            padding: 0px 0px 10px 20px;
        }
        .formbox form h3{
            font-size: 14px;
        }
        .formbox form .formcontrolbox .formlabel{
            font-size: 12px;
        }
        .formrightside h1{
            font-size: 20px;
        }
    }
}
@media screen and (min-width:768px) and (max-width:1150px){
    .Modalcontentbox{
        .formrightside h1{
            font-size: 28px;
        }
    }
}
@media screen and (max-width:600px){
    .mainbox{
        position: absolute;
        top: 55%!important;
        left: 50%;
        width: 70%!important;
        // height: 100%!important;
        // bgcolor: background.paper;
      //   border: "2px solid #000",
        box-shadow: 24;
        border-radius:1;
        padding: 0,
    }
    .Modalcontentbox{
        display: block;
        margin: 0px;
        .formbox{
            width: 100%;
            padding: 0px 10px;
            margin: 0px;
        }
        .formbox img{
            width: 60%;
            margin: auto;
            display: flex;
            justify-content: center;
        }
        .formbox form h3{
            font-size: 13px;
            margin: 0px;
        }
        form .formcontrolbox{
            margin-bottom: 0px;
        }
        form .formcontrolbox input{
            height: 12px;
        }
        form .formcontrolbox input::placeholder{
            font-size: 12px;
        }
        .formbox form .formcontrolbox .formlabel{
            font-size: 12px;
            margin: 0px;
        }
        .formbox .captchabox{
            height: 30px;
        }
        .formbox .captchabox p{
            font-size: 16px;
        }
        .formrightside {
            width: 50%;
            display: none;
        }
        .formrightside h1{
            font-size: 20px;
            box-sizing: border-box;
            width: 100%;
        }
    }
}