// ------------Upcoming--------------------

.upcomingTableMainDiv{
    border: 2px solid gray;
    border-radius: 6px;
    padding: 10px;
    .upcomingInsideMain{
        display: flex;
    justify-content: space-between;
    
    }
    button{
        font-size: 12px;
        font-family: Inter;
        padding: 8px 15px;
        font-weight: 600;
        border: none;
        background-color: #2D256C;
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }
}

.mobilescreencard{
    display: none!important;
}

@media screen and (max-width: 600px) {
    .thecardbox{
        display: none!important;
    }

    .desktopscreencard{
        display: none!important;
    }
    // ---------------------tables entire card box--------------------------------
    .mobilescreencard{
        display: block!important;
        .mobilecontentcard{
            padding: 5px!important;
            .appointmentid{
                color: #2D2828!important;
                font-size: 10px!important;
                font-weight: bold;
            }
            .dateheading{
                color: #D83F87!important;
                font-size: 10px;
                font-weight: bold;
            }
            .date{
                color: #444444!important;
                font-size: 10px!important;
                font-weight: bold;
            }
            .speciality{
                font-size: 10px!important;
                background-color: #FFEFF9;
                padding: 5px;
                color: gray;
                border-radius: 5px;
                width: 80%;
                text-align: center;
            }
            .timeheading{
                color: #D83F87!important;
                font-size: 10px;
                font-weight: bold;
            }
            .time{
                color: #444444!important;
                font-size: 10px!important;
                font-weight: bold;
            }
        }
        .openbtn{
            background-color: #00984F!important;
        }
        .cancelbtn{
            margin-top:15px;
        }
    }
}