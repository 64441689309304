@import "./_variables.scss";

* {
  margin: 0;
}
body {
  background-color: $backgroundColor;
  color: $textColor;
  font-family: Inter;
}

.scrollbar-thumb {
  background: #2d256c!important;
}
.scrollbar-thumb .scrollbar-thumb-x{
  width: 400px!important;
}

.b2ccontent{
  background: linear-gradient(150deg, #2d0969, #100344);
}

.myFont {
  font-family: Inter !important;

  .css-kqitpe-MuiTypography-root,
  .css-b3u0fe-MuiTypography-root,
  .css-1cfc9r8-MuiTypography-root,
  .css-ntm91v-MuiTypography-root,
  .css-1hmqe4e-MuiTypography-root,
  .css-q052d6-MuiTypography-root,
  .css-tjvwag-MuiTypography-root,
  .css-1hvp3fg-MuiTypography-root,
  .css-se5jak-MuiTypography-root,
  .css-yzvdu9-MuiTypography-root,
  .css-mfb148-MuiTypography-root,
  .css-ibgdc6-MuiTypography-root,
  .css-1oxc9iz-MuiTypography-root,
  .css-2yf8dz-MuiTypography-root  {
    font-family: Inter !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primaryColor;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primaryColor;
}

.appbar {
  background-color: $primaryColor;
  width: 98.4%;
  padding: 0.8%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;

  .imagee {
    width: 12vw;
    height: 40px;
    margin-left: 6px; //changed
  }

  .profile {
    padding-right: 30px; // added
    span {
      padding: 0.2em 0.8em;
      cursor: pointer;
    }
    .options {
      display: none;
    }
    &:hover {
      .options {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: $buttonColor;
        align-items: center;
        border-radius: 0.3em;
        gap: 5px; //added
        font-size: small;
        width: 5rem;
      }
    }
  }

  .icons {
    margin: 0 0.9em;
  }

  .appbar-right {
    display: flex;
    align-items: center;
  }
}

#headingbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 8px 0px;
}
#headingbox h6 {
  font-weight: 800;
  font-size: 17px;
  color: #d83f87;
}
#headingbox button {
  font-size: 12px;
  font-family: Inter;
  padding: 5px 15px;
  font-weight: 600;
  border: none;
  background-color: #d83f87;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  svg {
    // color: #2D256C;
    font-size: 0.9rem;
  }
}

// tabs and card between gap
.MuiTabPanel-root {
  padding: 3px 0px 0px 0px !important;
}
// changes done here
@media screen and (min-width: 600px) and (max-width: 768px) {
  #headingbox {
    align-items: center;
  }
  .appbar {
    height: 10vh;
    a {
      .imagee {
        width: 24vw !important; //changed
      }
    }
  }
  .profile {
    .options {
      display: none !important;
    }
  }
}

@media screen and (max-width: 600px) {
  #headingbox {
    align-items: center;
  }
  .appbar {
    height: 10vh;
    a {
      .imagee {
        width: 40vw !important; //changed
      }
    }
  }
  .profile {
    .options {
      display: none !important;
    }
  }
}

.flexAlagAlag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.leftbar {
  background-color: #fff;
  width: 14.2%;
  height: 100vh;
  padding: 0.3% 2%;
  position: fixed;
  box-shadow: 2px 0px 10px 2px rgba(128, 128, 128, 0.096);

  .menu-items {
    display: flex;
    align-items: center;
  }
  .submenu-item {
    display: flex;
    align-items: center;
    padding-left: 10px;
    h6 {
      font-size: 15px !important;
    }
  }
}

.central-body {
  margin-left: 18%;
  padding: 1% 2.7%;
}

.headings {
  font-weight: bold !important;
}

.buttonNext {
  display: flex;
  justify-content: center;
  width: auto;
  margin-top: 2%;
}
.leftsideicons {
  display: flex;
  justify-content: right;
}
.icons {
  margin-top: 90%;
}

.main-div {
  background-color: $primaryColor;
}

// -------------------------------------Login---------------------------------

.logincontent{
  background: linear-gradient(150deg, #2D0969, #100344);
}

.loginmainbody{
    padding: 6% 50px;
    height: 100%;
    .login-left img{
      width: 80%;
    }
    .login-right{
      background-color: #fff;
      img{
        width: 50%;
      }
      .formbox{
        padding: 3%;
        .loginoptions{
          width: 60%;
        }
        .text{
          font-size: 18px;
          color: #2D0969;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .subtext {
                margin-top: 4%;
                margin-bottom: 2%;
                font-size: 14px;
                font-weight: 600;
                line-height: 12px;
                color: #2D0969;
                letter-spacing: 0.30000001192092896px;
                text-align: left;
              }
        .otpbutton{
                display: flex;
                justify-content: center;
                padding: 10px 24px;
                background: #2d256c;
                border-radius: 6px;
                width: 100%;
                border: none;
                font-weight: 600;
                cursor: pointer;
                color: #fff;
              }
        .button {
                display: flex;
                justify-content: center;
                border: none;
                padding: 10px 24px;
                background: #2d256c;
                border-radius: 6px;
                width: 100%;
                cursor: pointer;
                color: #fff;
                margin-top: 10px;
                font-weight: 600;
              }
      }
    }
}



@media (max-width: 800px) {
  .central-body {
    margin-left: 0;
    padding: 3% !important;
  }

  .workingHours {
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch !important;

    .grids {
      width: 800px !important;
    }
  }

  .appbar {
    .imagee {
      width: 20vh !important;
    }

    // display: flex;
    // align-items: center;
  }
  .loginmainbody{
    margin-top: 50px;
    .login-left img{
      display: none;
    }
    .login-right{
      .formbox{
        .loginoptions{
          width: 100%;
        }
      }
    }
  }



  // .header {
  //   .header-menu {
  //     visibility: hidden;
  //     width: 0%;
  //   }

  //   img {
  //     height: auto;
  //     width: 100% !important;
  //   }
  //   button {
  //     background-color: $primaryColor;
  //     color: #fff;
  //     padding: 2% !important;
  //     border-radius: 8px !important;
  //     box-shadow: none;
  //     // font-size: small !important;
  //   }
  // }
  // .intro {
  //   .text-landing {
  //     margin-top: 0% !important;
  //     color: #faf0ca;
  //     font-weight: 800 !important;
  //     font-size: 30px !important;
  //     line-height: 35px !important;
  //     padding: 0% 10% !important;
  //     text-align: center !important;
  //   }
  //   .intro-button {
  //     margin: 10% 0 5% 0;
  //     display: flex;
  //     justify-content: center !important;
  //     padding-right: 0% !important;
  //   }
  //   button {
  //     background-color: #d83f87;
  //     color: #faf0ca;
  //     font-size: 16px;
  //     font-weight: 600;
  //     line-height: 19px;
  //     letter-spacing: 0em;
  //     text-align: center;
  //     height: 43.2802734375px !important;
  //     width: 178.99978637695312px !important;
  //     left: 90px !important;
  //     top: 529px !important;
  //     border-radius: 6px !important;
  //   }
  // }
  // .offerings {
  //   .text-offerings {
  //     font-size: 25px !important;
  //     font-weight: 900 !important;
  //     line-height: 38px !important;
  //     letter-spacing: 0em !important;
  //     text-align: center !important;

  //     border-radius: nullpx;
  //   }
  //   .subtext-offerings {
  //     font-size: 14px !important;
  //     font-weight: 300 !important;
  //     line-height: 21px !important;
  //     letter-spacing: 0em !important;
  //     text-align: center !important;
  //   }
  // }
  // .network {
  //   margin-top: 8%;
  //   img {
  //     width: 100%;
  //     height: auto;
  //   }
  //   .text-landing {
  //     margin-top: 0% !important;
  //     color: #faf0ca;
  //     font-weight: 800 !important;
  //     font-size: 30px !important;
  //     line-height: 35px !important;
  //     padding: 0% 10% !important;
  //     text-align: center !important;
  //   }
  //   .subtext-network {
  //     font-size: 14px !important;
  //     font-weight: 300 !important;
  //     line-height: 21px !important;
  //     letter-spacing: 0em !important;
  //     text-align: center !important;
  //   }
  // }
  // .specialities {
  //   margin-top: 4%;
  //   .text-specialities {
  //     font-size: 25px !important;
  //     font-weight: 900 !important;
  //     line-height: 38px !important;
  //     letter-spacing: 0em !important;
  //     text-align: center !important;

  //     border-radius: nullpx;
  //   }
  //   .dot {
  //     height: 80px !important;
  //     width: 80px !important;
  //     background-color: #bbb;
  //     border-radius: 50%;
  //     display: inline-block;
  //     background: #d83f87;
  //     border: 1px solid #0077b6;

  //     display: flex;
  //     align-content: center;
  //     flex-wrap: wrap;
  //     justify-content: center;
  //     margin: auto;
  //   }
  //   .text-circle {
  //     font-size: 14px !important;
  //     font-weight: 300 !important;
  //     line-height: 17px !important;
  //     letter-spacing: -0.01em !important;
  //     text-align: center !important;
  //   }
  // }
  // .clients {
  //   margin-top: 8%;
  //   .text-plans {
  //     font-size: 25px !important;
  //     font-weight: 900 !important;
  //     line-height: 38px !important;
  //     letter-spacing: 0em !important;
  //     text-align: center !important;

  //     border-radius: nullpx;
  //   }
  //   .subtext-plans {
  //     font-size: 14px !important;
  //     font-weight: 300 !important;
  //     line-height: 21px !important;
  //     letter-spacing: 0em !important;
  //     text-align: center !important;
  //   }
  // .image{

  //   text-align-last: end;
  //   .mobile{
  //     visibility: visible !important;
  //   }
  //   .desktop{
  //     visibility: hidden !important;
  //     width: 10% !important;
  //   }
  // }
  // }
  // .plans {
  //   margin-top: 10%;
  //   .left {
  //     align-items: start;
  //     .text1 {
  //       font-size: 10px !important;
  //       font-weight: 700 !important;
  //       line-height: 12px !important;
  //       letter-spacing: 0.08em !important;
  //       text-align: left !important;
  //     }
  //     .text2 {
  //       font-size: 25px !important;
  //       font-weight: 800 !important;
  //       line-height: 31px !important;
  //       letter-spacing: -0.01em !important;
  //       text-align: left !important;
  //     }
  //     .text3 {
  //       font-size: 14px !important;
  //       font-weight: 300 !important;
  //       line-height: 25px !important;
  //       letter-spacing: -0.01em !important;
  //       text-align: left !important;
  //     }
  //     button {
  //       background-color: #d83f87;
  //       color: #faf0ca;
  //       font-size: 16px;
  //       font-weight: 600;
  //       line-height: 19px;
  //       letter-spacing: 0em;
  //       text-align: center;
  //       height: 60px;
  //       width: 170px;
  //       left: 1175px;
  //       top: 578px;
  //       border-radius: 6px;
  //     }
  //   }
  //   .right{
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 100%;
  //     .dots{
  //       height: 40px !important;
  //       width: 80px !important;
  //       background-color: #bbb;
  //       border-radius: 50%;
  //       display: inline-block;
  //      padding: 5% !important;
  //       background: #d83f87;
  //       border: 1px solid #0077b6;
  //       margin-right: 1%;
  //       img{
  //         width: 100%;
  //         margin: auto;
  //       }
  //     }
  //   }
  //   .cards {
  //     background: #d83f87;
  //     border: 1px solid #0077b6;
  //     border-radius: 10px;
  //     width: 95% !important;
  //     padding: 2%;
  //     display: flex;
  //     align-content: center;
  //     flex-wrap: wrap;
  //     justify-content: center;
  //     margin-bottom: 1.5%;
  //     text-transform: capitalize;
  //     font-size: 12px !important;
  //     font-weight: 300 !important;
  //     line-height: 16px !important;
  //     letter-spacing: -0.01em !important;
  //     text-align: center !important;
  //   }
  //   button{
  //     background-color: #d83f87;
  //     color: #faf0ca;
  //     font-size: 16px;
  //     font-weight: 600;
  //     line-height: 19px;
  //     letter-spacing: 0em;
  //     text-align: center;
  //     height: 43.2802734375px !important;
  //     width: 178.99978637695312px !important;
  //     left: 90px !important;
  //     top: 529px !important;
  //     border-radius: 6px !important;
  //   }
  // }

  // .login {
  //   .login-left {
  //     visibility: hidden;
  //     img {
  //       width: 0% !important;
  //       height: 0vh !important;
  //       margin: auto;
  //       visibility: hidden;
  //     }
  //   }
  // }
}

a {
  text-decoration: none !important;
  color: #fff !important;
}
.links {
  color: $primaryColor !important;
}


// ------------------------------------------------customer footer----------------------------------

.customerfooter{
  margin-top: 10px;
}
.customerfooter p{
  font-size: 12px;
}

.customerfooter p a{
  color: #d83f87!important;
}



// .main-div {
//   .content {
//     padding: 5%;
//     color: #faf0ca;
//     text-transform: capitalize;
//   }
//   .header {
//     background-color: $buttonColor;
//     padding: 0.5% 1%;
//     display: flex;
//     justify-content: space-between;
//     color: #faf0ca;
//     align-items: center;
//     align-content: center;
//     .header-menu {
//       display: flex;
//       justify-content: space-between;
//       width: 50%;
//       margin-right: 10%;
//     }

//     img {
//       width: 40%;
//       height: 10%;
//     }

//     button {
//       background-color: $primaryColor;
//       color: #faf0ca;
//       padding: 0.8%;
//       border-radius: 12px;
//     }
//   }

//   .intro {
//     img {
//       width: 100%;
//       height: auto;
//     }
//     .text-landing {
//       margin-top: 20%;
//       color: #faf0ca;
//       text-align: end;
//       text-transform: capitalize;
//       font-weight: 900;
//       font-size: 49px;
//       line-height: 71px;
//       padding: 8% 0% 0 0%;
//     }
//     .intro-button {
//       display: flex;
//       justify-content: flex-end;
//     }
//     button {
//       background-color: #d83f87;
//       color: #faf0ca;
//       font-size: 16px;
//       font-weight: 600;
//       line-height: 19px;
//       letter-spacing: 0em;
//       text-align: center;
//       height: 60px;
//       width: 170px;
//       left: 1175px;
//       top: 578px;
//       border-radius: 6px;
//     }
//   }
//   .offerings {
//     margin-top: 8%;
//     .text-offerings {
//       color: #faf0ca;
//       text-align: center;
//       text-transform: capitalize;
//       font-weight: 900;
//       font-size: 49px;
//       line-height: 71px;
//       padding: 8% 0% 0 0%;
//     }
//     .subtext-offerings {
//       font-family: Inter;
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 29px;
//       letter-spacing: -0.08em;
//       text-align: center;
//     }
//     .cards {
//       background: #d83f87;
//       border: 1px solid #0077b6;
//       border-radius: 10px;
//       width: 80%;
//       padding: 8% 4%;
//       display: flex;
//       align-content: center;
//       flex-wrap: wrap;
//       justify-content: center;
//       margin: auto;

//     }
//     .card-heading {
//       font-size: 23px;
//       font-weight: 800;
//       line-height: 71px;
//       letter-spacing: -0.01em;
//       text-align: center;
//       width: 100%;
//     }
//     .card-subtext {
//       font-size: 18px;
//       font-weight: 400;
//       line-height: 22px;
//       letter-spacing: -0.01em;
//       text-align: center;
//     }
//   }
//   .network {
//     margin-top: 8%;
//     img {
//       width: 100%;
//       height: auto;
//     }
//     .text-landing {
//       margin-top: 20%;
//       color: #faf0ca;
//       text-align: end;
//       text-transform: capitalize;
//       font-weight: 900;
//       font-size: 49px;
//       line-height: 71px;
//       padding: 8% 0% 0 0%;
//     }
//     .subtext-network {
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 29px;
//       letter-spacing: -0.08em;
//       text-align: right;
//     }
//   }
//   .specialities {
//     margin-top: 8%;
//     .text-specialities {
//       text-align: center;
//       text-transform: capitalize;
//       font-weight: 900;
//       font-size: 49px;
//       line-height: 71px;
//       padding: 8% 0% 0 0%;
//       margin-bottom: 4%;
//     }
//     .dot {
//       height: 150px;
//       width: 150px;
//       background-color: #bbb;
//       border-radius: 50%;
//       display: inline-block;
//       background: #d83f87;
//       border: 1px solid #0077b6;

//       display: flex;
//       align-content: center;
//       flex-wrap: wrap;
//       justify-content: center;
//       margin: auto;
//     }
//     .text-circle {
//       margin: 1%;
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 29px;
//       letter-spacing: -0.08em;
//       text-align: center;
//     }
//   }
//   .clients {
//     margin-top: 8%;
//     .text-plans {
//       text-align: center;
//       text-transform: capitalize;
//       font-weight: 900;
//       font-size: 49px;
//       line-height: 71px;
//       padding: 8% 0% 0 0%;
//     }
//     .subtext-plans {
//       font-size: 20px;
//       font-weight: 500;
//       line-height: 29px;
//       letter-spacing: -0.08em;
//       text-align: center;
//       text-transform: capitalize;
//     }
//     .image{
//       width: 100%;
//       margin-top: 5%;
//       // margin: auto;
//     }
//     .desktop{
//       visibility: visible;
//     }
//     .mobile{
//       visibility: hidden;
//     }
//   }
//   .plans {
//     margin-top: 20%;
//     .left {
//       align-items: start;
//       .text1 {
//         font-size: 12px;
//         font-weight: 600;
//         line-height: 15px;
//         letter-spacing: 0.08em;
//         text-align: left;
//         padding: 0.5%;
//       }
//       .text2 {
//         font-size: 50px;
//         font-weight: 700;
//         line-height: 59px;
//         letter-spacing: -0.01em;
//         text-align: left;
//         padding: 0.5%;
//       }
//       .text3 {
//         font-size: 16px;
//         font-weight: 400;
//         line-height: 25px;
//         letter-spacing: -0.01em;
//         text-align: left;
//         padding: 0.5%;
//       }
//       button {
//         background-color: #d83f87;
//         color: #faf0ca;
//         font-size: 16px;
//         font-weight: 600;
//         line-height: 19px;
//         letter-spacing: 0em;
//         text-align: center;
//         height: 60px;
//         width: 170px;
//         left: 1175px;
//         top: 578px;
//         border-radius: 6px;
//       }
//     }
//     .right{
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 100%;
//       .dots{
//         height: 50px;
//         width: 50px;
//         background-color: #bbb;
//         border-radius: 50%;
//         display: inline-block;
//        padding: 1%;
//         background: #d83f87;
//         border: 1px solid #0077b6;
//         margin-right: 1%;
//         img{
//           width: 100%;
//           margin: auto;
//         }
//       }
//     }
//     .cards {
//       background: #d83f87;
//       border: 1px solid #0077b6;
//       border-radius: 10px;
//       width: 80%;
//       padding: 2%;
//       display: flex;
//       align-content: center;
//       flex-wrap: wrap;
//       justify-content: center;
//       margin-bottom: 1.5%;
//       text-transform: capitalize;
//       font-size: 22px;
//       font-weight: 400;
//       line-height: 27px;
//       letter-spacing: -0.01em;
//       text-align: left;
//     }
//   }
//   .footer{
//     margin-top: 5%;
//     text-align: center;
//   }
// }

// .login {
//   .login-left {
//     background-color: #fff;
//     img {
//       width: 80%;
//       height: 90vh;
//       margin: auto;
//     }
//   }
//   .login-right {
//     padding: 5%;
//     margin-top: 30%;
//     align-items: left;
//     img {
//       width: 50%;
//     }
//     .text {
//       font-size: 20px;
//       font-weight: 700;
//       line-height: 28px;
//       letter-spacing: 0px;
//       text-align: left;
//       margin-bottom: 2%;
//     }
//     .subtext {
//       margin-top: 4%;
//       margin-bottom: 2%;
//       font-size: 11px;
//       font-weight: 400;
//       line-height: 12px;
//       letter-spacing: 0.30000001192092896px;
//       text-align: left;
//     }
//     .button {
//       margin-top: 2%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       padding: 10px 24px;
//       width: 100%;
//       background: #2d256c;
//       border-radius: 6px;
//       color: #fff;
//     }
//   }
// }

//---------------------------------------!Loader---------------------------------------
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: $primaryColor;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

// --------------------------------termsandconditions---------------

.termconditionbody{
  background: linear-gradient(150deg, #2D0969, #100344);
}
.termNCon {
  color: #FFFFFF;
  padding: 6% 50px;
  h1 {
    text-align: left;
    color: #FAF0CA;
    padding: 2% 0px 2% 1.5%;
  }
}

@media (max-width: 600px) {
  
.termNCon {
  padding: 16% 50px 6% 50px;
  h1{
    font-size: 24px;
    padding: 4% 0% 3% 2%
  }
}
}


// ----------------------------------privacypolicy---------------------------
.privacypolicycontent{
  background: linear-gradient(150deg, #2D0969, #100344);
}
.privacypolicy{
  color: #FFFFFF;
  padding: 6% 5%;
  h1{
    text-align: left;
    color: #FAF0CA;
    padding: 2% 0px 2% 0%;
  }
  h2{
    color: #FAF0CA;
  }
}

@media (max-width: 600px) {
  .privacypolicy{
    padding: 6% 12% 0px 12%;
    h1{
      padding: 15% 0px 2% 0%;
    }
    h2{
      font-size: 20px;
    }
    h3{
      font-size: 20px;
    }
  }
}