.terms_conditions_box {
  h3 {
    text-align: center;
  }
  
    div {
      display: flex;
      justify-content: center;
    }
  

  table {
    font-family: "Inter", sans-serif;
    border-collapse: collapse;
    width: 60%;
  }

  td {
    border: 1px solid #969494;
    text-align: left;
    padding: 8px;
    width: 50%;
  }

  tr {
    border: 1px solid #969494;
    text-align: left;
    padding: 8px;
    width: 100%;
  }

  tr:nth-child(even) {
    background-color: #eeeeee;
  }
}
