// -------------------clinics clinic view---------------------
.clinicViewdetailsCard{
    box-shadow: none!important;
    border: 0.5px solid #D9D9D9;
}
.clinicdetailsmainbox{
    display:flex;
    gap: 10px;
}
.clinicleftbox{
    .clinicdetailsbox{
        display: flex;
        gap: 10px;
        .details{

            h5{
                color: #2D256C!important;
                font-size: 17px;
            }
            div{
                display: flex;
                h6{
                    font-size: 14px;
                }
            }
            p{
                font-size: 10px;
                color: #D83F87;
                font-weight: 600;
            }
        }
    }
}
.clinicrightbox{
    background-color: #F5F3F7;
    width: 70%;
    padding: 20px;
    border: 0.5px solid #D9D9D9;
    .financialdetailsbox{
        background-color: #FFFFFF;
        padding: 10px;
        border-radius: 5px;
        margin-top: 5px;
    }
}

// -----------------carousel-----------------
.react-multi-carousel-list{
    padding: 10px;
}
.item{
    padding:20px!important;
}
.planitem{
    padding: 10px 0px!important;
    margin-right: 20px;
}
.doctorscarouselbox{
    h5{
        margin-top: 10px;
        color: #000000!important;
        font-size: 17px;
    }
}

#carouselcontainer{
    .react-multi-carousel-list{
        padding: 0px;
    }
    .item{
        padding: 0px!important;
    }
}

.mobileClinicViewdetailsbox{
    display: none!important;
}


// ---------------------------------tablet mode-------------------------------

@media screen and (min-width: 600px) and (max-width: 768px) {
    .clinicdetailsmainbox{
        flex-wrap: wrap;
        .clinicleftbox{
            .clinicdetailsbox{
                align-items: center;
            }
        }
        .clinicrightbox{
            width: 100%;
        }
    }
}
// -------------------------media screen for mobile view---------------------
@media screen and (max-width: 600px) {
    .clinicViewdetailsCard{
        display:none!important;
    }
    .mobileClinicViewdetailsbox{
        display: block!important;
        .clinicimagesbox{
            display: flex;
            gap: 5px;
            .clinicmainimage{
                object-fit: cover;
                img{
                    height: 250px;
                    width: 300px!important;
                }
            }
            .subimages{
                display: flex;
                flex-direction: column;
                gap: 5px!important;
                .imagebox{
                    width: 80px!important;
                    height: 80px!important;
                    box-sizing: border-box;
                    img{
                        width: 80px!important;
                        height: 80px!important;
                    }
                }
            }
        }
        .clinicdetails{
            background-color: #FFF6FF;
            padding: 8px;
            margin-top: 10px;
            border: 0.5px solid #D9D9D9;
            .clinicnameAndAddress{
                .details{
                    display: flex;
                    gap: 5px;
                    div{
                        h5{
                            font-size: 12px;
                            color: #2D256C!important;
                        }
                    }
                    .address{
                        display: flex;
                        svg{
                            font-size: 10px;
                        }
                        p{
                            font-size: 10px;
                            color: #444444!important;
                        }
                    }
                    h6{
                        font-size: 10px;
                        color: #D83F87;
                        padding-left: 10px;
                    }
                }
                .specilalitiesbtnbox{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                    button{
                        border: none;
                        border-radius: 5px;
                        font-size: 10px;
                        padding: 5px;
                        background-color: #FFFFFF;
                        color: #2D256C;
                    }
                }
            }
        }
        .clinicOwnerdetailsbox{
            background-color: #FFF6FF;
            margin-top: 10px;
            padding: 10px!important;
            box-shadow: none!important;
            border: 0.5px solid #D9D9D9;
            .financialdetailsbox{
                background-color: #FFFFFF;
                padding: 10px;
                border-radius: 5px;
                .accountdetails{
                    font-size: 12px;
                    color: #444444;
                    font-weight: 600;
                }
            }
        }
        .doctordetailsbox{
            h5{
                font-size: 14px;
                margin-top: 10px;
            }
            .doctordetailscard{
                margin-top: 10px;
            }
        }
    }
    
}
@media screen and (min-width:350px) and (max-width:405px){
    .mobileClinicViewdetailsbox{
        .clinicimagesbox{
            display: flex;
            gap: 5px;
            .clinicmainimage{
                object-fit: cover;
                img{
                    height: 250px;
                    width: 250px!important;
                }
            }
            .subimages{
                display: flex;
                flex-direction: column;
                gap: 5px!important;
                .imagebox{
                    width: 80px!important;
                    height: 80px!important;
                    box-sizing: border-box;
                    img{
                        width: 80px!important;
                        height: 80px!important;
                    }
                }
            }
        }
    }
}