.thecardbox {
  // border: 1px solid black!important;
  height: 70vh;
  overflow-y: scroll !important;
}

.mobilescreencard {
  display: none !important;
}
.b2bplansmaintablebox {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  margin-bottom: 10px;
  .divider {
    border: 1px solid #d9d9d9;
  }
  .tablecontentbox1 {
    padding: 10px;
    width: 20%;
    .idhead {
      color: #d83f87;
      font-weight: bold;
      font-size: 10px;
    }
  }
  .tablecontentbox2 {
    padding: 10px;
    width: 60%;
    .box1 {
      display: flex;
      justify-content: space-between;
      //   gap: 80px;
      padding: 0px 2%;
      .subbox1 {
        // width: 20%;
        p {
          font-weight: bold;
          font-size: 10px;
          color: #d83f87;
        }
        h6 {
          color: #444444;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .subbox2 {
        // width: 40%;
        .moqbox {
          margin-bottom: 10px;
          p {
            font-weight: bold;
            font-size: 10px;
            color: #d83f87;
          }
          h6 {
            color: #444444;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
      .subbox3 {
        // width: 40%;
        p {
          font-weight: bold;
          font-size: 10px;
          color: #d83f87;
        }
        h6 {
          font-size: 12px !important;
        }
      }
    }
    .box2 {
      padding: 0px 2%;
      .heading {
        font-weight: bold;
        font-size: 10px;
        color: #d83f87;
      }
      .specialitybox {
        display: flex;
        gap: 5px;
        text-align: center;
        flex-wrap: wrap;
        border-radius: 5px;
        margin-top: 10px;
        p {
          font-size: 10px;
          background-color: #ffeff9;
          padding: 5px;
          color: #444444;
        }
      }
    }
  }
  .tablecontentbox3 {
    padding: 10px;
    background-color: #F5F5F5;
    width: 20%;
    .box1 {
      p {
        font-weight: bold;
        font-size: 10px;
        color: #d83f87;
      }
      h6 {
        font-size: 12px !important;
      }
    }
    .box2 {
      p {
        font-weight: bold;
        font-size: 10px;
        color: #d83f87;
      }
      h6 {
        font-size: 12px !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .thecardbox {
    display: none !important;
  }

  // ---------------------tables entire card box--------------------------------
  .mobilescreencard {
    display: block !important;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .mobilecontentcard {
      padding: 5px !important;
      box-shadow: none !important;
      border: 0.5px solid #d9d9d9;

      .speciality {
        font-size: 10px !important;
        background-color: #ffeff9;
        padding: 5px;
        color: gray;
        border-radius: 5px;
        width: 80%;
        text-align: center;
      }
    }
  }
}




// ---------------------------------------Mycoupons styles---------------------------------------

.thecardbox {
    // border: 1px solid black!important;
    height: 70vh;
    overflow-y: scroll !important;
  }
  
  .mobilescreencard {
    display: none !important;
  }
  .couponsmaintablebox {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
    .divider {
      border: 1px solid #d9d9d9;
    }
    .tablecontentbox1 {
      padding: 10px;
      width: 20%;
      .idhead {
        color: #d83f87;
        font-weight: bold;
        font-size: 10px;
      }
    }
    .tablecontentbox2 {
      padding: 10px;
      width: 60%;
      .box1 {
        display: flex;
        // justify-content: space-between;
        gap: 80px;
        padding: 0px 2%;
        .subbox1 {
          width: 30%;
          p {
            font-weight: bold;
            font-size: 10px;
            color: #d83f87;
          }
          h6 {
            color: #444444;
            font-size: 12px;
            font-weight: bold;
          }
        }
        .subbox2 {
          width: 30%;
          .moqbox {
            margin-bottom: 10px;
            p {
              font-weight: bold;
              font-size: 10px;
              color: #d83f87;
            }
            h6 {
              color: #444444;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
        
      }
      .box2 {
        padding: 0px 2%;
        .heading {
          font-weight: bold;
          font-size: 10px;
          color: #d83f87;
        }
        .specialitybox {
          display: flex;
          gap: 5px;
          text-align: center;
          flex-wrap: wrap;
          border-radius: 5px;
          margin-top: 10px;
          p {
            font-size: 10px;
            background-color: #ffeff9;
            padding: 5px;
            color: #444444;
          }
        }
      }
    }
    .tablecontentbox3 {
      padding: 10px;
      background-color: #F5F5F5;
      width: 20%;
      .box1 {
        p {
          font-weight: bold;
          font-size: 10px;
          color: #d83f87;
        }
        h6 {
          font-size: 12px !important;
        }
      }
      .box2 {
        p {
          font-weight: bold;
          font-size: 10px;
          color: #d83f87;
        }
        h6 {
          font-size: 12px !important;
        }
      }
    }
  }
  
  @media screen and (max-width: 600px) {
    .thecardbox {
      display: none !important;
    }
  
    // ---------------------tables entire card box--------------------------------
    .mobilescreencard {
      display: block !important;
      box-shadow: none !important;
      border: 0.5px solid #d9d9d9;
      .mobilecontentcard {
        padding: 5px !important;
        box-shadow: none !important;
        border: 0.5px solid #d9d9d9;
  
        .speciality {
          font-size: 10px !important;
          background-color: #ffeff9;
          padding: 5px;
          color: gray;
          border-radius: 5px;
          width: 80%;
          text-align: center;
        }
      }
    }
  }