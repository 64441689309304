.entirebox {
  h5 {
    padding-bottom: 10px;
    font-size: 17px;
  }
}
.maincard {
  display: flex;
  gap: 20px;
  box-shadow: none !important;
  border: 0.5px solid #d9d9d9;
  .leftcard {
    padding: 0px !important;
    width: 65%;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .leftcardhead {
      padding: 10px;
      background-color: #f0f0f0;
      h5 {
        font-size: 10px;
      }
      h4 {
        font-size: 12px;
        color: #2d2828;
        font-weight: bold;
      }
    }
    // .checkboxes{
    //     display: flex;
    //     justify-content: space-between;
    // }
    .leftcardbody {
      form {
        padding: 0px 15px 15px 15px;
      }
      h5 {
        font-size: 12px;
        margin-top: 10px;
        font-weight: 600;
      }
    }
  }
  .rightcard {
    width: 35%;
    padding: 0px !important;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .rightheadbox {
      background-color: #f0f0f0;
      padding: 3.5% !important;
      .subbox1 {
        h5 {
          font-size: 12px;
          font-weight: bold;
        }
        h4 {
          display: flex;
          color: #444444;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .rightbodybox {
      padding: 4% !important;
      h4 {
        font-size: 12px;
        font-weight: bold;
        color: #444444;
      }
      .appointmentscard {
        padding: 0px !important;
        margin-top: 10px;

        box-shadow: none !important;
        border: 0.5px solid #d9d9d9;
        .cardhead {
          display: flex;
          justify-content: space-between;
          background-color: #f0f0f0;
          padding: 2% !important;
          h5 {
            font-size: 12px;
            font-weight: bold;
          }
          svg {
            font-size: 12px;
            color: #d83f87;
          }
        }
        .cardbodybox {
          padding: 2% !important;
          .datebox {
            display: flex;
            gap: 10px;
            svg {
              font-size: 12px;
            }
            h4 {
              font-size: 12px;
              font-weight: bold;
            }
          }
          .specialitybox {
            width: 30%;
            margin-top: 10px;
          }
          .analysisbox {
            margin-top: 10px;
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.mobilecard {
  display: none !important;
}

// ------------------------------------Media screen for treatment plan--------------------------------
@media screen and (max-width: 600px) {
  .desktopcard {
    display: none !important;
  }
  // .entirebox{
  //     padding-left: 20px!important;
  // }
  .treatmentplan {
    font-size: 16px !important;
  }
  .mobilecard {
    display: block !important;
    margin-top: 10px;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .bodycard {
      padding: 0px !important;
      box-shadow: none !important;
      border: 0.5px solid #d9d9d9;
      .cardhead {
        padding: 10px;

        h5 {
          font-size: 10px;
        }
        h4 {
          font-size: 14px;
          color: #2d2828;
          font-weight: bold;
        }
      }
      .customernamebox {
        background-color: #f5f5f5;
        .subbox1 {
          h5 {
            font-size: 12px;
            font-weight: bold;
          }
          h4 {
            display: flex;
            color: #444444;
            font-size: 10px;
            font-weight: bold;
          }
        }
      }
      .leftcardbody {
        h5 {
          font-size: 14px;
          margin-top: 10px;
        }
        form {
          padding: 0px 15px 15px 15px;
        }
      }
      .previousappointmentbox {
        padding: 1% !important;
        h4 {
          font-size: 10px;
          font-weight: bold;
          color: #444444;
        }
        .appointmentscard {
          padding: 0px !important;
          margin-top: 10px;
          box-shadow: none !important;
          border: 0.5px solid #d9d9d9;
          .cardhead {
            display: flex;
            justify-content: space-between;
            background-color: #f0f0f0;
            padding: 2% !important;
            h5 {
              font-size: 10px;
              font-weight: bold;
            }
            svg {
              font-size: 14px;
              color: #d83f87;
            }
          }
          .cardbodybox {
            padding: 2% !important;
            .datebox {
              display: flex;
              gap: 10px;
              svg {
                font-size: 12px;
              }
              h4 {
                font-size: 10px;
                font-weight: bold;
              }
            }
            .specialitybox {
              width: 30%;
              margin-top: 10px;
            }
            .analysisbox {
              margin-top: 10px;
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
// ------------------------------------appointment billing first----------------------------------
.appointmentbillheading {
  font-size: 17px !important;
}
.subtexts {
  font-size: 14px !important;
}

// ------------------------------------Media screen appointment billing first--------------------------
@media screen and (max-width: 600px) {
  .appointmentbillheading {
    font-size: 16px !important;
  }

  .subtexts {
    font-size: 14px !important;
  }
  .proceduresnames {
    color: #444444;
    font-weight: 600 !important;
  }
  .proceduresvalue {
    color: #444444;
    font-weight: 600 !important;
  }
}

// ----------------------------------Appointment billing second styles-----------------------------------------

.totalpaybill {
  color: #d83f87 !important;
  font-weight: 600;
}

.billingMobilebodycard {
  display: none;
}

.appointmentbillheading {
  font-size: 17px !important;
}
@media screen and (max-width: 600px) {
  .appointmentbillheading {
    font-size: 16px !important;
  }
  .billingbodycard {
    display: none;
  }
  .billingMobilebodycard {
    display: block;
  }
  .totalpaybill {
    color: #d83f87 !important;
    font-weight: 600;
    h6 {
      font-size: 14px;
    }
  }
}
