.testOrderHistoryMainDiv {
  .heading {
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }
  .upcomingTabMainCard {
    .testUpcoming {
      height: 70vh;
      overflow-y: scroll !important;
      .list {
        margin-top: 5px;
        .firstGridItem {
          .idhead {
            color: #d83f87;
            font-weight: bold;
            font-size: 10px;
          }
          .emailBox {
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }
        .secondGridItem {
          .subItem1 {
            .name {
              font-weight: bold;
              font-size: 12px;
            }
            .locationBox {
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }
          .subItem2 {
            .datebox {
              p {
                font-weight: bold;
                font-size: 10px;
                color: #d83f87;
              }
              h6 {
                color: #444444;
                font-size: 12px;
                font-weight: bold;
              }
            }
            .bookingId {
              p {
                font-weight: bold;
                font-size: 10px;
                color: #d83f87;
              }
              h6 {
                color: #444444;
                font-size: 12px;
                font-weight: bold;
              }
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }
          .subItem3 {
            p {
              font-weight: bold;
              font-size: 10px;
              color: #d83f87;
            }
            h6 {
              color: #444444;
              font-size: 12px;
              font-weight: bold;
            }
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }
        .thridGridItem {
          // background-color: rgba(245, 245, 245, 1);
          padding: 16px 5px 0px 5px;
          .btnsBox {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 5px;
            // @media screen and (max-width: 600px) {
            //   button {
            //   }
            // }
          }
        }
      }
    }
  }

  //   ****************************OrderHIstory************************
  .orderHistoryTabMainCard {
    .testOrderHistory {
      height: 70vh;
      overflow-y: scroll !important;
      .list {
        margin-top: 5px;

        .firstGridItem {
          .idhead {
            color: #d83f87;
            font-weight: bold;
            font-size: 10px;
          }
          .emailBox {
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }
        .secondGridItem {
          .subItem1 {
            .name {
              font-weight: bold;
              font-size: 12px;
            }
            .locationBox {
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }
          .subItem2 {
            .datebox {
              p {
                font-weight: bold;
                font-size: 10px;
                color: #d83f87;
              }
              h6 {
                color: #444444;
                font-size: 12px;
                font-weight: bold;
              }
            }
            .bookingId {
              p {
                font-weight: bold;
                font-size: 10px;
                color: #d83f87;
              }
              h6 {
                color: #444444;
                font-size: 12px;
                font-weight: bold;
              }
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }
          .subItem3 {
            p {
              font-weight: bold;
              font-size: 10px;
              color: #d83f87;
            }
            h6 {
              color: #444444;
              font-size: 12px;
              font-weight: bold;
            }
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }
        .thridGridItem {
          // background-color: rgba(245, 245, 245, 1);
          padding: 16px 5px 0px 5px;
          .btnsBox {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 5px;
            // @media screen and (max-width: 600px) {
            //   button {
            //   }
            // }
          }
        }
      }
    }
  }
}
