.appointmentmaincard {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
  box-shadow: none !important;
  border: 0.5px solid #d9d9d9 !important;

  .leftcard {
    width: 60%;
    padding: 0px !important;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9 !important;
    .headbox {
      display: flex;
      justify-content: space-between;
      background-color: #f0f0f0;
      padding: 2% !important;
      .subbox1 {
        h5 {
          font-size: 10px;
          font-weight: bold;
        }
        h4 {
          color: #2d2828;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .bodybox {
      padding: 2% !important;
      .datetimebox {
        display: flex;
        gap: 50px;
        h5 {
          font-size: 10px;
          font-weight: bold;
        }
        h4 {
          color: #2d2828;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .specialitybox {
        width: 15%;
        margin-top: 20px;
      }
      .remarksbox {
        width: 50%;
        margin-top: 20px;
        h5 {
          font-size: 10px;
          font-weight: bold;
        }
        p {
          font-size: 12px;
          color: #444444;
        }
      }
    }
  }
  .rightcard {
    width: 35%;
    padding: 0px !important;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9 !important;
    .rightheadbox {
      background-color: #f0f0f0;
      padding: 3.5% !important;
      .subbox1 {
        h5 {
          font-size: 12px;
          font-weight: bold;
        }
        h4 {
          display: flex;
          color: #444444;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .rightbodybox {
      padding: 4% !important;
      h4 {
        font-size: 12px;
        font-weight: bold;
        color: #444444;
      }
      .previousAppointmentmainBox {
        max-height: 70vh;
        overflow-y: scroll;
      }
      .appointmentscard {
        padding: 0px !important;
        margin-top: 10px;
        box-shadow: none !important;

        border: 0.5px solid #d9d9d9 !important;
        .cardhead {
          display: flex;
          justify-content: space-between;
          background-color: #f0f0f0;
          padding: 2% !important;
          h5 {
            font-size: 12px;
            font-weight: bold;
          }
          svg {
            font-size: 12px;
            color: #d83f87;
          }
        }
        .cardbodybox {
          padding: 2% !important;
          .datebox {
            display: flex;
            gap: 10px;
            svg {
              font-size: 12px;
            }
            h4 {
              font-size: 12px;
              font-weight: bold;
            }
          }
          .specialitybox {
            width: 30%;
            margin-top: 10px;
          }
          .analysisbox {
            margin-top: 10px;
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .apoointinfo {
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  .appointmentmaincard {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .leftcard {
      width: 100%;
      .specialitybox {
        width: 35% !important;
        margin-top: 20px;
      }
    }
    .rightcard {
      width: 100%;
    }
  }
}

// ----------------------------------------------PastAppointment Info styles-------------------------------------

.pastappointmentmaincard {
  box-shadow: none !important;
  border: 0.5px solid #d9d9d9;
  .appointmentcard {
    padding: 0px !important;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .headbox {
      background-color: #f0f0f0;
      padding: 2%;
      h5 {
        font-size: 10px;
        font-weight: bold;
      }
      h4 {
        color: #2d2828;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .datetime {
      padding: 2%;
      gap: 50px;
      .date {
        h5 {
          font-size: 10px;
          font-weight: bold;
        }
        h4 {
          color: #2d2828;
          font-size: 12px;
          font-weight: bold;
        }
      }
      .time {
        h5 {
          font-size: 10px;
          font-weight: bold;
        }
        h4 {
          color: #2d2828;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .specialitybox {
      width: 25%;
      border-radius: 5px !important;
      background-color: #ffeff9;
      margin-left: 2%;
    }
    .remarksbox {
      width: 50%;
      padding-left: 2%;
      margin: 10px 0px;
      h5 {
        font-size: 10px;
        font-weight: bold;
      }
      p {
        font-size: 12px;
        color: #444444;
      }
    }
  }
  .complaintstreatmentscard {
    margin-top: 10px;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .complaints1 {
      font-size: 12px;
    }
    .complaints2 {
      font-size: 12px;
    }
    .procedures1 {
      font-size: 12px;
    }
    .procedures2 {
      font-size: 12px;
    }
  }
  .paymentcard {
    margin-top: 10px;
    padding: 0px !important;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .paymentdetailsdesc {
      background-color: #f0f0f0;
      padding: 2%;
      h5 {
        font-size: 14px;
      }
    }
    .paymentdetailsbox {
      padding: 2%;
      .totalchargesgrid {
        padding: 1% 0% !important;
        color: #d83f87;
        h5 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
      .sideheading {
        font-size: 14px !important;
      }
      .amount {
        font-size: 14px !important;
      }
      .treatments {
        font-size: 14px !important;
        color: #4f4f4f;
      }
      .value {
        font-size: 14px !important;
        color: #898989;
      }
      .amountpaidgrid {
        padding: 1% 0% !important;
        color: #d83f87;
        h5 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }

  // --------------------rightcard-----
  .rightcard {
    padding: 0px !important;
    box-shadow: none !important;
    border: 0.5px solid #d9d9d9;
    .rightheadbox {
      background-color: #f0f0f0;
      padding: 3.5% !important;
      .subbox1 {
        h5 {
          font-size: 12px;
          font-weight: bold;
        }
        h4 {
          display: flex;
          color: #444444;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .rightbodybox {
      padding: 4% !important;
      h4 {
        font-size: 12px;
        font-weight: bold;
        color: #444444;
      }
      .previousAppointmentmainBox {
        max-height: 70vh;
        overflow-y: scroll;
      }
      .appointmentscard {
        padding: 0px !important;
        margin-top: 10px;
        box-shadow: none !important;
        border: 0.5px solid #d9d9d9;
        .cardhead {
          display: flex;
          justify-content: space-between;
          background-color: #f0f0f0;
          padding: 2% !important;
          h5 {
            font-size: 12px;
            font-weight: bold;
          }
          svg {
            font-size: 14px;
            color: #d83f87;
          }
        }
        .cardbodybox {
          padding: 2% !important;
          .datebox {
            display: flex;
            gap: 10px;
            svg {
              font-size: 12px;
            }
            h4 {
              font-size: 12px;
              font-weight: bold;
            }
          }
          .specialitybox {
            width: 30%;
            margin-top: 10px;
          }
          .analysisbox {
            margin-top: 10px;
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .pastappointmentmaincard {
    .paymentcard {
      .paymentdetailsbox {
        padding: 2%;
        .sideheading {
          font-size: 12px;
        }
        .amount {
          font-size: 12px;
        }
        .totalchargesgrid {
          padding: 1% 0% !important;
          color: #d83f87;
          h5 {
            font-size: 12px;
          }
          p {
            font-size: 12px;
          }
        }
        .amountpaidgrid {
          padding: 1% 0% !important;
          color: #d83f87;
          h5 {
            font-size: 12px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
