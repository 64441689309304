.hamburger {
  display: none;
}

.hamburger-elements {
  display: none !important;
}

// active is added here
.hamburger-elements ul a.active {
  color: #1a0655;
  font-weight: 600;
  position: relative;
}

@media screen and (max-width: 768px) {
  .notificationicon {
    display: none !important;
  }
  .profileicon {
    display: none !important;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    color: #ffffff;
  }
  .hamburger-elements {
    display: block !important;
    background-color: #2d256c !important;
  }
  .hamburger-elements ul {
    padding: 0px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin: 10px auto !important;
  }
  .hamburger-elements ul .menu-items {
    display: flex;
  }
  .hamburger-elements ul .submenu-item {
    display: flex;
    padding-left: 10px;
  }
  .hamburger-elements > a {
    display: flex;
    width: 100%;
    justify-content: left;
  }

  // .logo {
  //   display: none;
  // }
  .smallscreenlogo {
    display: block;
    width: 28%;
  }
  .navbar {
    height: 61px;
    background-color: #fff;
  }

  .hamburger-elements ul li:last-child {
    margin-right: 60px;
  }

  .hamburger-elements {
    position: absolute;
    right: 0;
    width: auto;
    /* flex-direction: column; */
    display: block;
    top: 58px;
    background-color: #d83f87;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .hamburger-elements.active {
    width: 270px;
  }
}

.hamburger-elements .appbar-menu-items {
  display: flex;
  gap: 10px;
}

.sidemenuicon {
  height: 25px !important;
}
