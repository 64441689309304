.mainpersondetails{
    padding: 0px!important;
    .namebox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FFFFFF;
        background-color: #2d256c;
        padding: 1.5%;
        h5{
            font-size: 16px;
            color: #FFFFFF!important;
        }
        p{
            font-size: 16px;
        }
    }
    .personaldetails{
        padding: 1.5%;
        .heading{
            font-size: 10px;
            color: #D83F87;
            font-weight: 600;
        }
        .content{
            font-weight: 600;
        }
    }
}

.plandetails{
    padding: 0px!important;
    .planbox{
        color: #FFFFFF;
        background-color: #2d256c;
        padding: 2%;
        h5{
            font-size: 16px;
            color: #FFFFFF!important;
        }
    }
    .personaldetails{
        padding: 2%;
        .heading{
            font-size: 10px;
            color: #D83F87;
            font-weight: 600;
        }
        .content{
            font-weight: 600;
        }
    }
}

.membercarddetails{
    padding: 0!important;
    .detailsbox{
        padding: 2%;
        .heading{
            font-size: 10px;
            color: #D83F87;
            font-weight: 600;
        }
        .content{
            font-weight: 600;
        }
    }
    .healthinfobox{
        padding: 2%;
        h4{
            font-size: 16px;
            font-weight: 600;
        }
    }
}