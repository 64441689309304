.cardbox{
    .card{
        padding: 10px;
        // background-color: #FFFFFF;
        overflow-y: scroll;
        height: 80vh;
        .carddata{
            display: flex;
            padding: 10px;
            
            gap: 20px;
            background-color: #FFFFFF;
            // border: 1px solid black;
            border-radius: 5px;
            margin: 0px 25px 10px 25px;
            align-items: center;
            h5{
                color: #444444!important;
            }
            h6{
                color: #D83F87!important;
                font-size: 16px;
            }
            .address{
                color: #444444!important;
                font-size: 16px;
            }
            .kmsbox{
                display: flex;
                background-color:#FFEFF9;
                padding: 5px;
                gap: 5px;
                width:"80px";
                align-items: center;
                img{
                    height: 15px;
                }
                p{
                    font-size: 12px;
                }
            }
            .specialitybox{
                display: flex;
                gap: 10px;
                margin-top: 10px;
                margin-bottom: 10px;
                p{
                    font-size: 12px;
                    background-color: #FFEFF9;
                    padding: 5px 10px;
                }
            }
            button{
                background-color: #2D256C;
                border: none;
                padding: 10px;
                color: white;
                border-radius: 5px;
            }
        }
    }
}