.thecardbox{
    // border: 1px solid black!important;
    height: 70vh;
    overflow-y: scroll!important;
}
.mobilescreencard{
    display: none!important;
}
.tablemainbox{
    
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
    .divider{
        border: 1px solid #D9D9D9;
    }
    .tablecontentbox1{
        padding: 10px;
        width: 18%;
        .idhead{
            color: #D83F87;
            font-weight: bold;
            font-size: 10px;
        }
        // .request{
        //     width: 90%;
        //     font-size: 12px;
        //     background-color: #2D256C;
        //     padding: 10px;
        //     color: #FFFFFF;
        //     border-radius: 5px;
        // }
    }
    .tablecontentbox2{
        padding: 10px;
        gap: 10px;
        width: 62%;
        display: flex;
        justify-content: space-between;
        .subbox1{
            width: 40%;
            .name{
                font-weight: bold;
                font-size: 12px;
            }
            .specialitybox{
                text-align: center;
                width: 60%;
                background-color: #FFEFF9;
                border-radius: 5px;
                margin-top: 10px;
            }
        }
        .subbox2{
            width: 35%;
            .datebox{
                margin-bottom: 10px;
                p{
                    font-weight: bold;
                    font-size: 10px;
                    color: #D83F87;
                }
                h6{
                    color: #444444;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
            .timebox{
                p{
                    font-weight: bold;
                    font-size: 10px;
                    color: #D83F87;
                }
                h6{
                    color: #444444;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }
        .subbox3{
            width: 25%;
            p{
                font-weight: bold;
                font-size: 10px;
                color: #D83F87;
            }
            h6{
                font-size: 12px;
            }
        }
    }
    .tablecontentbox3{
        padding: 25px 10px 10px 10px;
        background-color: #F5F5F5;
        width: 20%;
        display: flex;
        justify-content: center;
        div{
            margin-bottom: 5px;
            width: 70%;
            button{
                width:100%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .thecardbox{
        display: none!important;
    }
    #appointReq{
        h5{
            font-size: 16px!important;
        }
            padding-left: 5px!important;
        
    }
    // ---------------------tables entire card box--------------------------------
    .mobilescreencard{
        display: block!important;
        .mobilecontentcard{
            padding: 5px!important;
            .appointmentid{
                color: #2D2828!important;
                font-size: 10px!important;
                font-weight: bold;
            }
            .dateheading{
                color: #D83F87!important;
                font-size: 10px;
                font-weight: bold;
            }
            .date{
                color: #444444!important;
                font-size: 10px!important;
                font-weight: bold;
            }
            .speciality{
                font-size: 10px!important;
                background-color: #FFEFF9;
                padding: 5px;
                color: gray;
                border-radius: 5px;
                width: 80%;
                text-align: center;
            }
            .timeheading{
                color: #D83F87!important;
                font-size: 10px;
                font-weight: bold;
            }
            .time{
                color: #444444!important;
                font-size: 10px!important;
                font-weight: bold;
            }
            .reschedulebtn{
                width: 90%!important;
            }
            .cancelbtn{
                width: 90%!important;
            }
        }
        
    }
}