.headingbox{
    display: flex;
    justify-content: space-between;
}
.services{
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    gap: 30px;
    div{
        p{
            font-weight: 600;
            padding-top: 10px;
            text-align: center;
            font-size: 12px;
            color: #D83F87;
        }
    }
    .squarebox{
        background-color:#FFF6FF;
        height: 120px;
        border: 0.3px solid #e4e4e4;
        border-radius: 5px;
        width: 120px;
        display: flex;
        justify-content: center;
        img{
            height: 60px;
            width: 60px;
            padding-top: 30px;
        }
    }
}
@media screen and (max-width: 600px) {
    .specialitiescard{
        overflow-y: scroll!important;
    }
}
// ----------------------------procedures-------------------------
.contentcard{
    margin-top: 20px;
    height: 70vh;
    box-shadow: none!important;
    border: 0.5px solid #D9D9D9;
    .bodycard{
        padding:0px!important;
        height: 70vh;
        overflow-y: scroll!important;
        box-shadow: none!important;
        border: 0.5px solid #D9D9D9;
        .heading{
            background-color: #F0F0F0;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h5{
                font-size: 14px;
            }
        }
    }
}