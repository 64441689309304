@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

/* :root{
    background-color: #1A0655;
} */
.Homepage {
  background: linear-gradient(150deg, #2d0969, #100344);
}
.landingpageContent {
  padding: 50px 50px;
  margin: 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  /* border: 1px solid white; */
  /* overflow: hidden; */
  /* background-color: #1A0655; */
  /* background: linear-gradient(150deg, #2d0969, #100344); */
  animation: animate-background 10s infinite ease-in-out;
}

@media screen and (min-width: 330px) and (max-width: 650px) {
  .landingpageContent {
    padding: 50px 20px;
  }
  /* .scroll-content{
        transform: translate3d(0px,auto,0px)!important;
    } */
  /* .scrollbar-track-x{
        width: 400px!important;
    }
    .scrollbar-thumb-x{
        width: 400px!important;
    } */
}
@keyframes animate-background {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

#up {
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background-image: linear-gradient(
    80deg,
    rgb(5, 124, 172),
    rgb(43, 247, 202, 0.5)
  );
  filter: blur(80px);
  animation: down 40s infinite;
}

#down {
  position: absolute;
  right: 0;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background-image: linear-gradient(
    80deg,
    rgba(245, 207, 82, 0.8),
    rgba(199, 10, 114)
  );
  filter: blur(80px);
  animation: up 40s infinite;
}

#diagonal {
  position: absolute;
  width: 280px; /* Adjust the width as needed for your diagonal shape */
  height: 380px; /* Adjust the height as needed for your diagonal shape */
  background-image: linear-gradient(45deg, #d83f87, #03e1ff);
  filter: blur(90px);
  animation: moveDiagonal 30s linear infinite alternate;
  transform-origin: center;
  top: 0;
  left: 0;
}
/*   
  #left {
    position: absolute;
    height: 500px;
    width: 500px;
    border-radius: 50%;
    background-image: linear-gradient(
      80deg,
      rgb(5, 124, 172),
      rgba(183, 253, 52, 0.8)
    );
    filter: blur(80px);
    animation: left 30s 1s infinite;
  }
  
  #right {
    position: absolute;
    height: 500px;
    width: 500px;
    border-radius: 50%;
    background-image: linear-gradient(
      80deg,
      rgba(26, 248, 18, 0.6),
      rgba(199, 10, 52, 0.8)
    );
    filter: blur(80px);
    animation: right 40s 0.5s infinite;
  } */

@keyframes down {
  0%,
  100% {
    top: -100%;
    opacity: 2;
  }
  70% {
    top: 500vh;
  }
}

@keyframes up {
  0%,
  100% {
    bottom: -100%;
    opacity: 2;
  }
  70% {
    bottom: 100vh;
  }
}

@keyframes moveDiagonal {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  100% {
    transform: translateX(100vw) translateY(100vh) rotate(360deg);
  }
}

/*   
  @keyframes left {
    0%,
    100% {
      left: -100%;
    }
    70% {
      left: 100%;
    }
  }
  
  @keyframes right {
    0%,
    100% {
      right: -100%;
    }
    70% {
      right: 100%;
    }
  } */

.cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  /* border: 1px solid #f5f5f5; */
  border-radius: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.1s;
}

.cursor2 {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: #c6c6c6;
  border-radius: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 0.15s;
}

.landingpageContent:hover ~ .cursor {
  transform: translate(-50%, -50%) scale(1);
  background-color: #ed64a6;
  opacity: 0.5;
}

/* ---------------------headContent---------------------- */
.headContent {
  display: flex;
  /* border: 1px solid white; */
  justify-content: center;
  align-items: center;
}
.headContent .mobile {
  display: none;
}
.headContent #headimagebox {
  width: 60%;
}
#headimagebox #headimage {
  width: 90%;
}
.headContent #headtext {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 15px;
  width: 40%;
  color: #faf0ca;
  /* height: max-content; */
}
#headtext h1 {
  font-size: 40px;
  color: #faf0ca;
  font-weight: 900;
  font-family: "Inter", sans-serif;
}

#headtext h6 {
  margin-top: 20px;
  color: #ffffff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  letter-spacing: 0.00714em;
}

#headtext h1 .typing-text {
  color: #d83f87;
}

/* #headtext p .typing-text::before{
    content: "Affordable";
    display: inline-block;
    width: 0;
    white-space: nowrap;
    height: 40px;
    overflow: hidden;
    animation: typing 3s steps(10) forwards, erasing 1s 2s steps(10) infinite;
} */

/* 
  @keyframes typing {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes erasing {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  } */

#headtext h1 .blinking-image {
  opacity: 0;
  animation: blink 1.5s infinite; /* Starts blinking after 2 seconds and repeats */
  height: 40px;
  color: #d83f87;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    fill: pink; /* Change the color to pink */
  }
}

.getstarted {
  /* width: 170px; */
  /* height: 60px; */
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
  background-color: #d83f87;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
}
.getstarted:hover {
  color: #d83f87;
  background-color: #fff;
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  #headtext p {
    font-size: 35px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  #headtext p {
    font-size: 27px;
  }
  #headtext h1{
    font-size: 22px;
  }
  #headtext h1 .blinking-image{
    height: 24px;
  }
  .getstarted{
    padding: 8px 15px;
    font-size: 14px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  #headtext p {
    font-size: 34px;
  }
  #headtext h1{
    font-size: 22px;
  }
  #headtext h1 .blinking-image{
    height: 24px;
  }
  .getstarted{
    padding: 10px 30px;
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .headContent #headimagebox {
    /* display: none; */
    margin: auto;
    width: 100%;
  }
  #headimagebox #headimage {
    width: 100%;
  }
  .headContent {
    display: block;
    margin: 60px 0px 50px;
  }
  .headContent #headtext {
    width: 100%;
    text-align: center;
    padding-right: 0px;
  }

  .headContent .mobile {
    display: block !important;
    font-size: 34px;
    color: #faf0ca;
    font-weight: 900;
    text-align: center;
    font-family: "Inter", sans-serif;
  }

  .headContent .mobile .typing-text {
    border-right: none;
    height: 22px;
    color: #d83f87;
  }

  .headContent .mobile .blinking-image {
    height: 22px;
    opacity: 0;
    animation: blink 1.5s infinite; /* Starts blinking after 2 seconds and repeats */
    height: 40px;
    color: #d83f87;
  }

  #headtext .desktop {
    display: none;
  }

  .getstarted {
    margin-top: 10px;
    padding: 10px 30px;
  }
}

/* ---------------------OfferingsBox--------------------------- */
.offeringsBox {
  box-sizing: border-box;
  /* width: 80vw; */
  /* border: 1px solid rgba(255,255,255,0.3); */
  width: 93%;
  margin: auto;
  text-align: center;
  /* background-color: rgba(255, 255, 255, 0.1); */
  /* background-image: linear-gradient(80deg, #75b8f7, rgb(52, 27, 190)); */
  border-radius: 10px;
  padding-bottom: 30px;
  /* background: rgba(255,255,255,0.2); */
  /* box-shadow: 0 4px 30px rgba(0,0,0,0.1); */
  backdrop-filter: blur(5px);
}
.offeringsBox .offeringsTitleAndDesc {
  width: 60%;
  margin: auto;
  margin-top: 100px;
}
.offeringsBox .offeringsTitleAndDesc h2 {
  color: #faf0ca;
  font-size: 40px;
  line-height: 80px;
  margin: 0px;
  font-weight: 900;
}

.offeringsBox .offeringsTitleAndDesc p {
  color: #ffffff;
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  line-height: 22px;
}
.offeringCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
}
.offeringCards .offeringCard {
  text-align: center;
  background-color: #d83f87;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
}

.offeringCards .offeringCard:hover {
  background-color: #ffffff;
  color: #1a0655;
  cursor: pointer;
}
.offeringCards .offeringCard img {
  margin-top: 10px;
}

.offeringCards .offeringCard h4 {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 10px;
}
.offeringCards .offeringCard p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
@media (max-width: 600px) {
  .offeringsBox {
    /* background-color:#1A0655; */
    border-radius: 0px;
    padding-bottom: 0px;
    background-image: none;
  }
  .offeringsBox .offeringsTitleAndDesc {
    width: 100%;
    margin: auto;
    margin-top: 60px;
  }
  .offeringCards {
    margin-top: 30px;
  }
  .offeringsBox .offeringsTitleAndDesc p {
    color: #faf0ca;
    font-size: 12px;
    font-weight: 400;
  }
  .offeringsBox .offeringsTitleAndDesc h2 {
    color: #faf0ca;
    font-size: 25px;
    font-weight: 700;
  }
  .offeringCards .offeringCard h4 {
    font-size: 21px;
    font-weight: 800;
  }
  .offeringCards .offeringCard p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
@media screen and (max-width: 768px) {
  .offeringsBox .offeringsTitleAndDesc p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }
  .offeringsBox .offeringsTitleAndDesc h2 {
    color: #faf0ca;
    font-size: 29px;
    font-weight: 700;
  }
  .offeringCards .offeringCard h4 {
    font-size: 21px;
    font-weight: 800;
  }
  .offeringCards .offeringCard p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .offeringsBox .offeringsTitleAndDesc p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }
  .offeringsBox .offeringsTitleAndDesc h2 {
    color: #faf0ca;
    font-size: 24px;
    font-weight: 700;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1350px) {
  .offeringsBox .offeringsTitleAndDesc h2 {
    color: #faf0ca;
    font-size: 49px;
    font-weight: 800;
  }
}

/* ---------------------Our Network------------------- */

.ournetworkbox {
  display: flex;
  /* border: 1px solid white; */
  justify-content: center;
  margin-top: 140px;
  align-items: center;
}
.ournetworkbox #ournetworkimage {
  width: 60%;
}
.ournetworkbox #ournetworkimage #networkimage {
  padding: 80px 0px 0px 0px;
  width: 75%;
}
.ournetworkbox #ournetworktext {
  text-align: right;
  display: flex;
  padding-right: 16px;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  color: #ffffff;
  /* height: max-content; */
}
#ournetworktext h2 {
  font-size: 40px;
  color: #faf0ca;
  font-weight: 900;
  font-family: "Inter", sans-serif;
  margin: 0px 0px 10px 0px;
}
#ournetworktext p {
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  line-height: 22px;
}

#ournetworktext a p {
  color: #d83f87;
  margin-top: 10px;
  font-weight: bold;
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  #ournetworktext h2 {
    font-size: 35px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  #ournetworktext h2 {
    font-size: 24px;
  }
  #ournetworktext p {
    font-size: 15px;
    font-weight: 300;
    color: #ffffff;
  }
  .ournetworkbox #ournetworkimage #networkimage{
    padding: 0px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  #ournetworktext h2 {
    font-size: 25px;
  }
  #ournetworktext p {
    font-size: 15px;
    font-weight: 300;
    color: #ffffff;
  }
}

@media (max-width: 600px) {
  .ournetworkbox {
    margin: 60px 0px 50px;
    display: block;
  }
  .ournetworkbox #ournetworkimage {
    width: 100%;
  }
  .ournetworkbox #ournetworkimage #networkimage {
    width: 100%;
    padding: 50px 0px 0px 0px;
  }
  .ournetworkbox #ournetworktext {
    width: 100%;
    text-align: center;
  }
}

/* -------------------Specialities Covered------------------------ */
.specialbox {
  width: 93%;
  margin: auto;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  /* background-color: rgba(256,256, 246,0.1); */
  /* background-image: linear-gradient(80deg, #75b8f7, rgb(52, 27, 190)); */
  border-radius: 10px;
  padding-bottom: 30px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}
.specialities h2 {
  color: #faf0ca;
  /* color: #0A064C; */
  font-weight: 900;
  margin-top: 140px;
  font-size: 40px;
  text-align: center;
  padding-top: 15px;
  line-height: 71px;
}
.specialitiesbox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}
.specialitiesbox div p {
  color: #ffffff;
  padding: 10px 0px 0px;
}

.specialitiesbox .roundbox img {
  padding-top: 35px;
  height: 80px;
}
.roundbox {
  border-radius: 100%;
  background-color: #d83f87;
  /* border: 1px solid white; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  height: 150px;
  width: 150px;
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .specialities {
    font-size: 49px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .specialbox {
    background-color: #1a0655;
    width: 100%;
    border-radius: 0px;
    padding-bottom: 0px;
  }
  .specialities h2{
    font-size: 25px;
  }
  .specialitiesbox {
    font-size: 15px;
    gap: 25px;
  }
  .roundbox {
    height: 90px;
    width: 90px;
  }
  .specialitiesbox .roundbox img {
    padding-top: 20px;
    height: 50px;
  }
}

@media screen and (min-width: 400px) and (max-width: 768px) {
  .specialbox {
    background-color: #1a0655;
    width: 100%;
    border-radius: 0px;
    padding-bottom: 0px;
  }
  .specialities h2 {
    font-size: 25px;
    font-weight: 700;
    padding-top: 0px;
    margin-top: 60px;
    color: #faf0ca;
  }
  .roundbox {
    height: 90px;
    width: 90px;
  }
  .specialitiesbox {
    font-size: 15px;
    gap: 25px;
  }
  .specialitiesbox .roundbox {
    text-align: center;
    margin: auto;
  }
  .specialitiesbox .roundbox img {
    padding-top: 20px;
    height: 50px;
  }
  .specialitiesbox div p {
    color: #ffffff;
  }
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .specialbox {
    background-color: #1a0655;
    width: 100%;
    border-radius: 0px;
    padding-bottom: 0px;
  }
  .specialities h2 {
    font-size: 25px;
    color: #ffffff;
    line-height: 50px;
    padding-top: 0px;
  }
  .roundbox {
    height: 60px;
    width: 60px;
  }
  .specialitiesbox {
    font-size: 10px;
    gap: 20px;
  }
  .specialitiesbox .roundbox img {
    padding-top: 15px;
    height: 30px;
  }
  .specialitiesbox .roundbox {
    text-align: center;
    margin: auto;
  }
  .specialitiesbox div p {
    color: #ffffff;
  }
}

/* ------------------------------Our Plans-------------------------- */

.planscarouselcard {
  width: 300px;
  padding: 20px !important;
}
.planscarouselcard h5 {
  font-size: 18px;
  font-weight: 900 !important;
}
.planscarouselcard .discounttreatments {
  display: flex;
  border-radius: 3px;
  width: 80%;
  padding: 2%;
  color: #100344 !important;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffbedd !important;
}
.planscarouselcard .discounttreatments p {
  font-size: 14px;
}
.discounttreatments .discountper {
  font-size: 30px !important;
}

.planscarouselcard .priceheading {
  font-size: 14px;
}
.react-multi-carousel--plans-list {
  padding-bottom: 25px !important;
}
.react-multi-carousel--plans-list {
  max-width: 90%;
  margin: auto;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .ourplansbox .ourplansheading {
    font-size: 25px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .ourplansbox .ourplansheading {
    font-size: 25px;
  }
}
@media screen and (min-width: 465px) and (max-width: 600px) {
  /* .planscarouselcard{
        width: 200;
    } */
    .ourplansbox .ourplansheading {
      font-size: 25px;
    }
    
}

@media screen and (min-width: 300px) and (max-width: 464px) {
  /* .planscarouselcard{
        width: 200;
    } */
  .ourplansbox .ourplansheading {
    font-size: 25px;
  }
}
@media screen and (max-width: 300px) {
  /* .planscarouselcard{
        width: 200px;
        justify-content: center;
        margin: auto;
    } */
  .ourplansbox .ourplansheading {
    font-size: 25px;
  }
}

/* ---------------------------dialog------------------------------ */
.plantitle {
  color: #ffffff;
  font-weight: bold !important;
  background-color: #d83f87;
}

/* -----------------------------------plancardcss------------------------------- */
.planType {
  font-size: 20px !important;
  font-weight: bold !important;
  padding-left: 30px !important;
  margin-top: 30px !important;
  color: #faf0ca;
}
.plansmainbox {
  display: flex;
  justify-content: center;
  gap: 35px;
  padding: 0px 30px;
  margin-top: 10px;
  flex-wrap: wrap;
}
/* .plansmainbox{
  position: relative;
} */
.plansmainbox .viewallitems {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 240px;
  border-radius: 5px;
  gap: 10px;
  background-color: #d83f87;
  align-items: center;
  cursor: pointer;
}
.viewallitems h5 {
  color: #ffffff !important;
}
.planscard {
  width: 200px;
  height: 350px;
  padding: 15px !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit;
}
.planscard .recommendedbox{
  position: absolute;
  top: 10px;
  background-color: #d83f87;
  color: #ffffff;
  padding: 5px 10px;
  /* border-radius: 5px; */
  float: left;
  width: 140px;
  text-align: center;
  font-size: 12px;
  /* border: 1px solid black; */
  /* -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%); */
  clip-path: polygon(15% 5%, 85% 5%, 100% 50%, 85% 95%, 15% 95%, 0% 50%);
}

.planscard .plansCardBody{
  width: 250px;
  height: 95%;
  border-radius: 8px;
}


.planscard h5 {
  font-size: 20px;
  font-weight: 600 !important;
  text-align: center;
  margin-top: 30px;
}
.planscard .discounttreatments {
  /* display: flex; */
  border-radius: 3px;
  width: 95%;
  padding: 2%;
  color: #100344 !important;
  margin-top: 10px;
  /* justify-content: space-between; */
  /* align-items: center; */
  text-align: center;
  /* background-color: #ffbedd !important; */
}
/* .planscard .discounttreatments p {
  font-size: 14px;
} */
.discounttreatments .discountper {
  font-size: 25px !important;
}
.planscard .discounttreatments h6{
  font-size: 16px;
  color:#444444 ;
  font-weight: 600;
}

.planscard .pricedetails{
  text-align: center;
}

.planscard .priceheading {
  font-size: 14px;
}


@media screen and (min-width: 465px) and (max-width: 600px) {
  /* .planscard{
        width: 200;
    } */
}

@media screen and (min-width: 320px) and (max-width: 464px) {
  .planscard {
    width: 250px;
  }
  .planType {
    padding-left: 0px!important;
  }
  .plansmainbox {
    justify-content: center;
    gap: 10px;
  }
  .ourplansbox .ourplansheading {
    font-size: 25px;
  }
  .ourplansbox p {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .planscard {
    width: 200px;
    justify-content: center;
    margin: auto;
  }
  .planType {
    padding-left: 0px!important;
  }
  .plansmainbox {
    justify-content: center;
    gap: 10px;
  }
  .ourplansbox .ourplansheading {
    font-size: 25px;
  }
  .ourplansbox p {
    text-align: center;
  }
}

/* --------------------------Our Clients------------------------ */

.ourclientsbox {
  margin-top: 150px;
  text-align: center;
}
.ourclientsbox .ourclientsboxtext {
  width: 80%;
  margin: auto;
}
.ourclientsbox h2 {
  font-size: 40px;
  color: #faf0ca;
  margin: 0px 0px 10px 0px;
  font-weight: 900;
}
.ourclientsbox p {
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  margin: 0px 0px 50px 0px;
}
.clientimagebox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}
@media (max-width: 600px) {
  .ourclientsbox {
    margin-top: 60px;
  }
  .ourclientsbox .ourclientsboxtext {
    width: 100%;
  }
  .ourclientsbox h2 {
    font-size: 25px;
  }
  .ourclientsbox p {
    font-size: 14px;
    font-weight: 300;
  }
  .clientimagebox > img {
    /* height: 20px; */
    width: 60px;
  }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
  .ourclientsbox .ourclientsboxtext {
    width: 100%;
  }
  .ourclientsbox h2 {
    font-size: 25px;
  }
  .ourclientsbox p {
    font-size: 16px;
    font-weight: 300;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .ourclientsbox h2 {
    font-size: 30px;
  }
  .ourclientsbox p {
    font-size: 17px;
    font-weight: 300;
  }
}

/* -------------------------------owl carousel--------------------------- */
.owl-carousel {
  margin: auto;
  width: 90%;
}
.carouselImages {
  height: 110px;
  width: 110px;
}
/* .owl-carousel .owl-item img{
    display: block;
    width: 110px;
    height: 110px;
  } */
.owl-prev {
  position: absolute;
  left: -50px;
  top: 0px;
}
.owl-next {
  position: absolute;
  right: -50px;
  top: 0px;
}
.owl-prev span,
.owl-next span {
  font-size: 80px;
  color: white;
  background-color: #1a0655;
}
.owl-nav button:hover {
  color: blue;
}
/* .owl-prev span:hover,.owl-next span:hover{
    background-color: white;
  } */
.owl-theme .owl-nav [class*="owl-"] {
  background-color: white !important;
}

/* ------------------------------Never Pay For OPD------------------------- */

.neverPayForGoBox {
  margin: 140px 0px 0px;
  display: flex;
  box-sizing: border-box;
  padding: 0px 30px;
  align-items: center;
  justify-content: space-between;
}
.neverPayForGoBox > .getstarted {
  display: none;
}
.neverPayForGoBox .opdinsurancesectionbox {
  padding: 0px;
  margin: 10px 0px;
}
.opdinsurance .desktopparagraphtag {
  font-size: 16px;
  margin: 0px 0px 10px 0px;
  font-weight: 300;
  color: #ffffff;
}
.opdinsurance .mobileparagraphtag {
  display: none;
}
.opdinsurance .insurancecontent p {
  font-size: 16px;
  margin: 0px 0px 10px 0px;
  font-weight: 300;
  color: #ffffff;
}
.opdinsurance h2 {
  font-size: 40px;
  margin: 0px 0px 10px 0px;
  font-weight: 900;
  width: 100%;
  line-height: 50px;
  color: #faf0ca;
}
.opdinsurance button {
  margin: 10px 0px 0px 0px;
}
.opdinsurancesectionbox .homeinsuranceimage {
  border-radius: 50%;
  height: 65px;
  width: 65px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  background-color: #d83f87;
  box-sizing: border-box;
}
.homeinsurancebox {
  display: flex;
  gap: 20px;
  align-items: center;
  color: #ffffff;
  margin-bottom: 10px;
}
.healthinsurancebox {
  display: flex;
  gap: 20px;
  align-items: center;
  color: #ffffff;
}
.homeinsuranceimage img {
  margin: 15px;
}
.opdinsurancesectionbox .healthinsuranceimage {
  border-radius: 50%;
  height: 65px;
  width: 65px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  box-sizing: border-box;
  background-color: #d83f87;
}
.healthinsuranceimage img {
  margin: 15px;
}

.insurancecontent p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

.opdinsurancerightbox {
  width: 90%;
  box-sizing: border-box;
}
.opdinsurancerightbox img {
  width: 90%;
  margin-left: 100px;
}

.opdinsurancerightbox .mobile {
  display: none;
}

@media screen and (min-width: 1100px) and (max-width: 1440px) {
  .opdinsurancerightbox img {
    width: 90%;
    margin-left: 100px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1100px) {
  .neverPayForGoBox .opdinsurancesectionbox {
    padding: 0px 0px 0px 0px;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .neverPayForGoBox .opdinsurancesectionbox {
    padding: 0px;
  }
  .neverPayForGoBox {
    display: block;
  }
  .homeinsurancebox {
    margin-bottom: 10px;
  }
  .insurancecontent {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.9px;
  }
  .opdinsurancesectionbox {
    margin-top: 20px;
  }
  .opdinsurance h2 {
    font-size: 25px;
    width: 60%;
    line-height: 30px;
  }
  .opdinsurancerightbox img {
    margin-left: 50px;
  }
}

@media screen and (min-width: 330px) and (max-width: 600px) {
  .neverPayForGoBox .opdinsurancesectionbox {
    padding: 0px;
  }
  .neverPayForGoBox {
    display: block;
    margin-top: 60px;
  }
  .neverPayForGoBox .opdinsurance {
    text-align: center;
  }
  .homeinsurancebox {
    margin-bottom: 10px;
  }
  .insurancecontent {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
  .opdinsurance .desktopparagraphtag {
    display: none;
  }
  .opdinsurance .mobileparagraphtag {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 400;
    color: #ffffff;
  }
  .opdinsurance h2 {
    font-size: 25px;
    width: 80%;
    text-align: center;
    margin: auto;
    line-height: 30px;
  }
  .opdinsurance .insurancecontent p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
  }
  .opdinsurancesectionbox {
    margin-top: 20px;
  }
  .opdinsurancesectionbox .insurancecontent p {
    text-align: left;
  }
  .opdinsurance .getstarted {
    display: none;
  }
  .neverPayForGoBox > .getstarted {
    display: block;
    margin: 30px auto 0px;
  }

  .opdinsurancerightbox .mobile {
    display: block;
    width: 80%;
  }
  .opdinsurancerightbox .desktop {
    display: none;
  }
  .opdinsurancerightbox {
    width: 100%;
  }
  .opdinsurancerightbox img {
    margin: 0px;
    width: 100%;
  }
}

@media screen and (max-width: 330px) {
  .neverPayForGoBox {
    display: block;
    padding: 0px;
    margin-top: 60px;
  }
  .neverPayForGoBox .opdinsurance {
    text-align: center;
  }
  .homeinsurancebox {
    margin-bottom: 10px;
  }
  .insurancecontent {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
  .opdinsurance .desktopparagraphtag {
    display: none;
  }
  .opdinsurance .mobileparagraphtag {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 400;
    color: #ffffff;
  }
  .opdinsurance h2 {
    font-size: 25px;
    width: 80%;
    text-align: center;
    margin: auto;
    line-height: 30px;
  }
  .opdinsurance .insurancecontent p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
  }
  .opdinsurancesectionbox {
    margin-top: 20px;
  }
  .opdinsurancesectionbox .insurancecontent p {
    text-align: left;
  }
  .opdinsurance .getstarted {
    display: none;
  }
  .neverPayForGoBox > .getstarted {
    display: block;
    margin: 30px auto 0px;
  }

  .opdinsurancerightbox .mobile {
    display: block;
    width: 80%;
  }
  .opdinsurancerightbox .desktop {
    display: none;
  }
  .opdinsurancerightbox {
    width: 100%;
  }
  .opdinsurancerightbox img {
    margin: 0px;
    width: 100%;
  }
}

/* ------------------------------------Customer ui for booking Appointment info---------------------- */
.bookappointmentinfomainbox {
  margin-top: 100px;
}
.bookappointmentinfomainbox h2 {
  color: #faf0ca;
  font-size: 40px;
  display: flex;
  justify-content: center;
  font-weight: 900;
}

.easybookappointmentinfobox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.easybookappointmentinfobox .imagecontent {
  width: 55%;
  box-sizing: border-box;
}
.easybookappointmentinfobox .imagecontent img {
  width: 85%;
}
.easybookappointmentinfobox .rightcontent {
  color: #ffffff;
  width: 45%;
}
.easybookappointmentinfobox .rightcontent h2 {
  color: #faf0ca;
  font-size: 40px;
  display: flex;
  justify-content: center;
  font-weight: 900;
  margin-bottom: 15px;
}
.easybookappointmentinfobox .rightcontent span {
  text-align: justify;
  justify-content: center;
}
.easybookappointmentinfobox .rightcontent .getbutton button {
  background-color: #d83f87;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  font-size: 16px;
  padding: 15px 30px;
  margin-top: 20px;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
.easybookappointmentinfobox .rightcontent .getbutton button:hover {
  background-color: #ffffff !important;
  color: #d83f87;
}

@media (max-width: 375px) {
  .bookappointmentinfomainbox h2 {
    font-size: 25px;
    text-align: center;
  }
  .easybookappointmentinfobox {
    display: block;
  }

  .easybookappointmentinfobox .imagecontent {
    width: 100%;
  }
  .easybookappointmentinfobox .imagecontent img {
    width: 100%;
  }
  .easybookappointmentinfobox .rightcontent {
    font-size: 14px;
    text-align: center;
    padding-left: 0px;
    width: 100%;
  }

  .easybookappointmentinfobox .rightcontent h2 {
    font-size: 25px;
  }
  .easybookappointmentinfobox .rightcontent .getbutton {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 650px) and (max-width: 768px) {
  .bookappointmentinfomainbox h2 {
    font-size: 25px;
    text-align: center;
  }
  .easybookappointmentinfobox {
    display: block;
  }
  .easybookappointmentinfobox .imagecontent {
    width: 100%;
  }
  .easybookappointmentinfobox .imagecontent img {
    width: 70%;
    margin: auto;
    display: flex;
  }
  .easybookappointmentinfobox .rightcontent {
    font-size: 14px;
    text-align: center;
    padding-left: 0px;
    width: 100%;
  }

  .easybookappointmentinfobox .rightcontent h2 {
    font-size: 25px;
  }
  .easybookappointmentinfobox .rightcontent .getbutton {
    display: flex;
    justify-content: center;
  }
  .easybookappointmentinfobox .rightcontent .getbutton button {
    padding: 10px 30px;
  }
}

@media screen and (min-width: 375px) and (max-width: 650px) {
  .bookappointmentinfomainbox h2 {
    font-size: 25px;
    text-align: center;
  }
  .easybookappointmentinfobox {
    display: block;
  }
  .easybookappointmentinfobox .imagecontent {
    width: 100%;
  }
  .easybookappointmentinfobox .imagecontent img {
    width: 100%;
  }
  .easybookappointmentinfobox .rightcontent {
    font-size: 14px;
    text-align: center;
    padding-left: 0px;
    width: 100%;
  }

  .easybookappointmentinfobox .rightcontent h2 {
    font-size: 25px;
  }
  .easybookappointmentinfobox .rightcontent .getbutton {
    display: flex;
    justify-content: center;
  }
  .easybookappointmentinfobox .rightcontent .getbutton button {
    padding: 10px 30px;
  }
}

/* ---------------------------------health plans--------------------------- */

.healthplanswholecontentbox {
  margin-top: 100px;
}
.healthplanswholecontentbox .healthplanheadingbox {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.healthplanswholecontentbox .healthplanheadingbox h2 {
  font-size: 40px;
  font-weight: 900;
  line-height: 50px;
  color: #faf0ca;
  margin: 0px 0px 10px 0px;
}
.healthplanswholecontentbox .healthplanheadingbox p {
  color: #ffffff;
  margin-bottom: 0px 0px 10px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.healthplansbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 70px 0px 20px;
  gap: 20px;
}
.healthplancard {
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 10px;
  color: #100344;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
  cursor: pointer;
}
.healthplancard.active {
  background-color: #d83f87;
  color: #ffffff;
}
.healthplancard p {
  padding: 20px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
/* .healthplancontent .healthplanstartedbtn{
    width: fit-content;
    border-radius: 25px;
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 8px;
}
.healthplancontent .healthplanstartedbtn input{
    border: none;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    width: 60%;
}
.healthplancontent .healthplanstartedbtn input::placeholder{
    padding-left: 10px;
}
.healthplancontent .healthplanstartedbtn input:focus{
    outline: none;
    border: none;
}
.healthplancontent .healthplanstartedbtn button{
    border: none;
    background-color: #0A064C;
    color: #FFFFFF;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}*/

.healthplancontent {
  width: 75%;
  box-sizing: border-box;
  animation: slide 20s infinite;
}

.healthplancontent img {
  width: 80%;
  object-fit: contain;
  height: 430px;
  transition: opacity 1s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* @media screen and (max-width:440px) {
    .healthplansbox{
        display: block;
        margin-top: 60px;
    }
    .healthplancontent h2{
        font-size: 25px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        line-height: 30px;
    }
    .healthplancontent p{
        font-size: 14px;
        width: 100%;
        text-align: center;
        line-height: 20px;
    }
    .healthplancontent .healthplanstartedbtn{
        width: 93%;
        display: flex;
        justify-content: space-between;
    }
    .healthplancontent .healthplanstartedbtn input{
        border-radius: 24px;
        font-size: 14px;
        font-weight: 500;
         width: 69%; 
    }
    .healthplancontent .healthplanstartedbtn input::placeholder{
        font-size: 14px;
        padding-left: 0px;
    }
    .healthplancontent .healthplanstartedbtn button{
        background-color: #D83F87;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 400;
        padding: 5px 10px;
    }
    .healthplancard{
        width: 100%;
    }
    .healthplancard p{
        padding: 12px;
        font-size: 14px;
        line-height: 15px;
        text-align: left;
    }
} */

@media screen and (min-width: 250px) and (max-width: 600px) {
  .healthplanswholecontentbox {
    display: none;
  }
  .healthplansbox {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .healthplansbox {
    display: block;
  }
  .healthplanswholecontentbox .healthplanheadingbox h2{
    font-size: 25px;
  }
  .healthplancontent h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    width: 100%;
  }
  .healthplancontent p {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    line-height: 23px;
  }
  .healthplancard p {
    padding: 16px;
    font-size: 14px;
    line-height: 15px;
  }
  .healthplancontent .healthplanstartedbtn input {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    width: 150px;
  }
  .healthplancontent .healthplanstartedbtn button {
    background-color: #d83f87;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }
}
@media screen and (min-width: 700px) and (max-width: 768px) {
  .healthplansbox{
    padding: 0px 20px 0px 20px;
  }
  .healthplancontent h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
  }
  
  .healthplanswholecontentbox .healthplanheadingbox h2{
    font-size: 25px;
    line-height: 30px;
  }
  .healthplancontent {
    width: 100%;
    box-sizing: border-box;
    animation: slide 20s infinite;
  }
  .healthplancontent p {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
  }
  .healthplancontent img {
    width: 100%;
    object-fit: contain;
    height: 430px;
    transition: opacity 1s ease-in-out;
  }
  .healthplancontent .healthplanstartedbtn input {
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    width: 160px;
  }
  .healthplancontent .healthplanstartedbtn button {
    font-size: 15px;
    font-weight: 400;
    padding: 5px 15px;
  }
  .healthplancard p {
    padding: 15px 20px;
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .healthplansbox{
    padding: 0px 20px 0px 20px;
  }
  .healthplancontent h2 {
    font-size: 39px;
    font-weight: 600;
    line-height: 50px;
  }
  .healthplancontent p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
  .healthplancontent .healthplanstartedbtn input {
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    width: 180px;
  }
  .healthplancontent .healthplanstartedbtn button {
    font-size: 15px;
    font-weight: 400;
    padding: 5px 15px;
  }
  .healthplancard p {
    padding: 15px 20px;
    font-size: 12px;
    line-height: 21px;
  }
}

/* ------------------------------Experience Insurance--------------------------- */

.experienceinsurancebox {
  margin: 100px auto 30px;
  text-align: center;
  width: 70%;
}
.experienceinsurancebox .box1 h2 {
  color: #faf0ca;
  font-size: 40px;
  line-height: 50px;
  margin: 0px 0px 10px 0px;
  font-weight: 900;
}
.experienceinsurancebox .box2 p {
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
@media screen and (min-width: 780px) and (max-width: 950px) {
  .experienceinsurancebox .box1 h2 {
    font-size: 48px;
    line-height: 60px;
  }
  .experienceinsurancebox .box2 p {
    font-size: 18px;
    line-height: 25px;
  }
}
@media screen and (min-width: 590px) and (max-width: 780px) {
  .experienceinsurancebox {
    width: 80%;
  }
  .experienceinsurancebox .box1 h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .experienceinsurancebox .box2 p {
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and (max-width: 590px) {
  .experienceinsurancebox {
    width: 90%;
  }
  .experienceinsurancebox .box1 h2 {
    font-size: 25px;
    line-height: 26px;
  }
  .experienceinsurancebox .box2 p {
    font-size: 14px;
    line-height: 21px;
  }
}

/* -----------------------------one solution for OPD------------------ */

.solutionforopdbox {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}
.solutionforopdbox .solutionforopdimg1 {
  width: 90%;
}
.solutionforopdbox .solutionforopdimg2 {
  display: none;
}
@media screen and (max-width: 600px) {
  .solutionforopdbox {
    margin-top: 30px;
  }
  .solutionforopdbox .solutionforopdimg1 {
    display: none;
  }
  .solutionforopdbox .solutionforopdimg2 {
    display: block;
  }
}
/* ---------------------------------Footer--------------------------- */
.footerbox {
  padding: 100px 70px 40px 70px;
  /* background: #070F2B;
  box-shadow: inset -5px -5px 15px rgba(0, 255, 255, 0.12), 
  inset 5px 5px 15px rgba(0, 255, 255, 0.12); */
  
  /* background-color: #100344; */
}
.footermenu {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 119px;
  color: #ffffff;
}
.footerlogosection {
  box-sizing: border-box;
  width: 33.3%;
}
.footerlogosection img {
  width: 45%;
}
.footerlogosection p {
  font-size: 14px;
  text-align: justify;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
}
.footermenuitems {
  display: flex;
  /* gap: 50px; */
  justify-content: space-around;
  width: 33.3%;
}
.footermenuitems div h3 {
  font-size: 20px;
}
.footermenuitems div p {
  font-size: 14px;
  margin-top: 10px;
}
.footermenu .signUpBox {
  width: 33.4%;
}
.footermenu .signUpBox h2 {
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.footermenu .signUpBox .subscribeBox {
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 24px;
}

.footermenu .signUpBox .subscribeBox .inputbox {
  padding-right: 0px !important;
  width: 99.8%;
  border: none;
  border-radius: 24px;
}
/* .footermenu .signUpBox .subscribeBox{
    width: fit-content;
    border-radius: 24px;
    background-color: #FFFFFF;
    padding: 5px;
}
.footermenu .subscribeBox input{
    border: none;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    width: 210px;
}
.footermenu .subscribeBox input:focus{
    outline: none;
    border: none;
}
.footermenu .subscribeBox button{
    border: none;
    background-color: #D83F87;
    color: #FFFFFF;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 16px;
    cursor: pointer;
} */

.footermenu .signUpBox .follow {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  justify-content: right;
}
.copyrights {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  color: #ffffff;
}
.follow {
  display: flex;
  gap: 20px;
}
.follow svg {
  color: #d83f87;
  font-size: 30px;
}
.footerbottom {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.footerbottom .copyrightstext {
  color: #ffffff;
  font-size: 12px;
}

.footerbottom .footercontact {
  color: #ffffff;
}
.footerbottom .footercontact p {
  font-size: 12px;
}

.footerbottom .footercontact p a {
  color: #d83f87 !important;
}

@media screen and (min-width: 450px) and (max-width: 1100px) {
  .footermenu {
    display: block;
  }
  .footerlogosection {
    width: 80%;
    margin: auto;
    text-align: center;
  }
  .footermenuitems {
    margin: auto;
    margin-top: 20px;
    justify-content: center;
    gap: 30px;
    width: 80%;
  }
  .footermenu .signUpBox {
    width: 80%;
    text-align: center;
    margin: auto;
  }

  .subscribeBox {
    margin: auto;
  }
  .footermenu .signUpBox h2 {
    text-align: center;
    margin-top: 20px;
  }
  .footermenu .signUpBox .follow {
    justify-content: center;
  }
}
@media screen and (max-width: 505px) {
  .footermenu .subscribeBox input {
    width: 66%;
  }
  .footermenu .subscribeBox button {
    padding: 5px 10px;
    font-size: 14px;
  }
  .footermenu .signUpBox h2 {
    text-align: center;
    margin-top: 30px;
    font-size: 17px;
  }
  .footermenu .signUpBox .follow {
    justify-content: center;
  }

  .footermenuitems {
    margin-left: 30px;
  }
  .footermenu {
    display: block;
  }
  .signUpBox {
    text-align: center;
    margin: 50px auto 0px;
  }
  .signUpBox .subscribeBox {
    margin: auto;
  }
}
@media screen and (min-width: 380px) and (max-width: 650px) {
  .footerbox {
    padding: 100px 30px 40px 30px;
  }
  .footerlogosection {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .footerlogosection img {
    width: 60%;
  }
  .footerlogosection p {
    text-align: center;
  }
  .footermenuitems {
    margin: auto;
    margin-top: 30px;
    justify-content: center;
    width: 100%;
    gap: 30px;
  }
  .footermenu .signUpBox {
    width: 90%;
    text-align: center;
    margin: auto;
  }
  .footerbottom {
    display: block;
  }
  .footerbottom .copyrightstext {
    margin: 10px 0px;
  }
}
@media screen and (max-width: 380px) {
  .footerbox {
    /* margin: 100px 5px 50px; */
    padding: 100px 30px 40px 30px;
  }
  .footerlogosection {
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .footerlogosection p {
    text-align: center;
  }
  .footermenuitems {
    margin: auto;
    margin-top: 20px;
    /* justify-content: center; */
    width: 90%;
  }
  .footermenu .signUpBox {
    width: 90%;
    text-align: center;
    margin: auto;
  }
  .footerbottom {
    display: block;
  }
  .footerbottom .copyrightstext {
    margin: 10px 0px;
  }
  /* .footermenu .subscribeBox input {
    width: 60px;
  }
  .footermenu .subscribeBox button {
    padding: 5px;
    font-size: 12px;
  }
  .signUpBox {
    text-align: center;
    margin: 20px 0px 0px 10px;
  }
  .signUpBox .subscribeBox {
    margin: auto;
  }

  .footermenu .signUpBox .follow {
    justify-content: center;
  } */
}

@media screen and (max-width: 768px) {
  .copyrights {
    display: block;
  }
  .copyrights .follow {
    justify-content: center;
  }
  .copyrights .copyrightstext {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
  .footerbottom .copyrightstext {
    text-align: center;
  }
  .footerbottom .footercontact {
    text-align: center;
  }
}
