@import "./_variables.scss";

.opdnetworkcontent{
    background: linear-gradient(150deg, #2D0969, #100344);
}

.ouropdnetworkmainbody{
    padding: 6% 50px;
    height: 100%;
    .headingbox{
        text-align: center;
        margin: auto;
        display: flex;
        flex-direction: column;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        h2{
            color: #FAF0CA;
            font-size: 40px;
            margin-top: 40px;
            font-weight: bold;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
        }
        p{
            color: #FFFFFF;
            font-size: 16px;
            font-weight: 400;
            width: 50%;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            line-height: 1.5;
            letter-spacing: 0.00938em;
        }
    }
    .contentbody{
        margin-top: 50px;
        .leftbox{
            box-sizing: border-box;
            img{
                width: 100%;
                
            }
        }
        .rightbox{
            padding: 2%;
            background-color: #FFFFFF;
            border-radius: 5px;
            .formlabel{
                font-size: 16px;
                font-weight: bold;
                color: #2d256c;
            }
            .clinicsContainer{
                padding: 2%;
                background-color: #f4f8ff;
                margin-top: 10px;
                .clinicsmainbox{
                    background-color: #FFFFFF;
                    max-height: 45vh;
                    overflow-y: auto;
                    border-radius: 3px;
                    .carddata{
                        display: flex;
                        gap: 20px;
                        align-items: center;
                        margin-top: 5px;
                        .leftside{
                            img{
                                height: 100px;
                                width: 170px;
                            }
                            .clinicmainpic{
                                height: 100px;
                                width: 170px;
                            }
                        }
                        .rightside{
                            h5{
                                font-size: 16px;
                                font-weight: bold;
                                color: rgb(74, 73, 73)!important;
                            }
                            h6{
                                font-size: 12px;
                                font-weight: 500;
                                color: #D83F87;
                            }
                            .address{
                                font-size: 10px;
                                color: black;
                            }
                            .specialitybox {
                                display: flex;
                                gap: 10px;
                                margin-top: 10px;
                                flex-wrap: wrap;
                                margin-bottom: 10px;
                                p {
                                  font-size: 10px;
                                  background-color: #ffeff9;
                                  padding: 5px 8px;
                                  border-radius: 2px;
                                }
                              }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
    .ouropdnetworkmainbody{
        .headingbox{
            h2{
                font-size: 25px;
            }
            p{
                width: 100%;
                font-size: 16px;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .ouropdnetworkmainbody{
        padding: 10% 40px;
        .headingbox{
            h2{
                font-size: 25px;
            }
            p{
                width: 100%;
                font-size: 14px;
            }
        }
        .contentbody{
            .rightbox{
                .clinicsContainer{
                    .clinicsmainbox{
                        .carddata{
                            .leftside{
                                img{
                                    height: 80px;
                                    width: 100px;
                                }
                                .clinicmainpic{
                                    height: 80px;
                                    width: 100px;
                                }
                            }
                            .rightside{
                                h5{
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: rgb(74, 73, 73)!important;
                                }
                                h6{
                                    font-size: 10px;
                                    font-weight: 500;
                                    color: #D83F87;
                                }
                                .address{
                                   display: none;
                                }
                                .specialitybox{
                                    gap: 5px;
                                    p{
                                        font-size: 8px;
                                        padding: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// ---------------------------------------B2CPaymentStatus Styles------------------------------------

.b2cpaymentmainbox{
    background: linear-gradient(150deg, #2D0969, #100344);
    padding: 6% 50px;
    height: 100%;
    margin-top: 50px;
}