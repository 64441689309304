.csecardBox{
    
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    display: flex;
    margin-bottom: 10px;
    .divider{
        border: 1px solid #D9D9D9;
    }
    .tablecontentbox1{
        padding: 10px;
        width: 16%;
        .idhead{
            color: #D83F87;
            font-weight: bold;
            font-size: 10px;
        }
    
    }
    .tablecontentbox2{
        padding: 10px;
        gap: 10px;
        width: 64%;
        display: flex;
        justify-content: space-between;
        .subbox1{
            width: 45%;
            .name{
                font-weight: bold;
                font-size: 12px;
            }
            .specialitybox{
                text-align: center;
                width: 60%;
                background-color: #FFEFF9;
                border-radius: 5px;
                margin-top: 10px;
            }
        }
        .subbox2{
            width: 30%;
            .datebox{
                margin-bottom: 10px;
                p{
                    font-weight: bold;
                    font-size: 10px;
                    color: #D83F87;
                }
                h6{
                    color: #444444;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
            .timebox{
                p{
                    font-weight: bold;
                    font-size: 10px;
                    color: #D83F87;
                }
                h6{
                    color: #444444;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
        }
        .subbox3{
            width: 25%;
            p{
                font-weight: bold;
                font-size: 10px;
                color: #D83F87;
            }
            h6{
                font-size: 12px;
            }
        }
    }
    .tablecontentbox3{
        padding: 25px 10px 10px 10px;
        background-color: #F5F5F5;
        width: 20%;
        display: flex;
        justify-content: center;

        
        div{
            margin-bottom: 5px;
            width: 70%;
            button{
                width:100%;
            }
        }
    }
}
